import React from 'react';
import NavBar from "../navbar/navbar";
import StickyFooter from "../footer";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    body: {
        backgroundColor: '#f5f5f7',
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
    },

    main: {
        width: '95%',
        maxWidth: '1600px',
        margin: "0 auto",
        marginBottom: '20px',
        paddingTop: "20px"
    }
}));

/**
 * Default layout to be used with pages which need the full viewport
 * Has navbar on top and sticky footer on the bottom
 */
export default function LargeLayout(props) {
  const classes = useStyles();
  return (
    <div className={classes.body}>
      <NavBar />
      <main className={classes.main}>
        {props.children}
      </main>
      <StickyFooter />
    </div>
  );
}
