import {
  Backdrop,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Hidden,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";
import ScenarioRow from "./scenario-row";
import ScenarioTableSearchField from "./scenario-table-search-field";

const useStyles = makeStyles((theme) => ({
  tableRow: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },

  button: {
    // marginRight: "10px",
    marginTop: "10px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    },
  },
}));

export default function ScenarioTable(props) {
  const classes = useStyles();

  const selectable = props.selectable || props.downloadable;
  const downloadable = props.downloadable;
  const page = props.page;
  const rowsPerPage = props.rowsPerPage;
  const count = props.count;
  const scenarios = props.scenarios || [];

  const handlePageChange = props.handlePageChange;
  const handleRowsPerPageChange = props.handleRowsPerPageChange;

  const [expandAll, setExpandAll] = React.useState(false);
  const [selectAll, setSelectAll] = React.useState(false);
  const [selected, setSelected] = React.useState(props.selected ? props.selected : []);

  const linkRef = React.createRef();

  /*
  Copied from scenario-row
   */
  const downloadSolution = (xml_url, scenario_id) => {
    fetch(xml_url, {
      method: "GET",
    })
      .then((res) => res.blob())
      .then((blob) => {
        const href = window.URL.createObjectURL(blob);
        const a = linkRef.current;

        const regex = /^(.+\/xml\/)([a-zA-Z_\-0-9]+\.(xml|zip))/;
        const found = xml_url.match(regex);
        const fileType = found[3];

        a.download = `${scenario_id}.${fileType}`;
        a.href = href;
        a.click();
        a.href = "";
      });
  };

  const handleDownloadButtonClick = () => {
    // selected => [{scenario_id, version}]
    if (props.selected !== undefined) {
      // need to extract XML file locations
      const selected_scenarios = scenarios.filter((s) =>
        props.selected.some(
          (_selected) => _selected["scenario_id"] === s["scenario_id"] && _selected["version"] === s["version"]
        )
      );
      selected_scenarios.forEach((elem) => downloadSolution(elem["xml"], elem["scenario_id"]));
    }
  };

  const handleRowSelect = (event, version) => {
    let _selected = [...selected];
    if (event.target.checked) {
      _selected.push({ scenario_id: event.target.name, version: version });
    } else {
      _selected.splice(
        _selected.findIndex((x) => x.scenario_id === event.target.name && x.version === version),
        1
      );
    }

    let allSelected = true;
    for (const scenario of props.scenarioIds) {
      allSelected &=
        _selected.findIndex((x) => x.scenario_id === scenario.scenario_id && x.version === scenario.version) !== -1;
    }

    props.setSelected(_selected);
    setSelectAll(allSelected);
    setSelected(_selected);
  };

  // TODO: need all scenario names
  const handleAllSelect = (event) => {
    let _selected = [...selected];
    if (event.target.checked) {
      for (const scenario of props.scenarioIds) {
        if (
          selected.findIndex((x) => x.scenario_id === scenario.scenario_id && x.version === scenario.version) === -1
        ) {
          _selected.push(scenario);
        }
      }

      props.setSelected(_selected);
      setSelected(_selected);
      setSelectAll(true);
    } else {
      props.setSelected([]);
      setSelected([]);
      setSelectAll(false);
    }
  };

  React.useEffect(() => {
    let allSelected = true;
    for (const scenario of props.scenarioIds) {
      allSelected &=
        selected.findIndex((x) => x.scenario_id === scenario.scenario_id && x.version === scenario.version) !== -1;
    }
    setSelectAll(allSelected);
  }, [scenarios]);

  React.useEffect(() => {
    setSelected(props.selected ? props.selected : []);
  }, [props.selected]);

  return (
    <React.Fragment>
      {/*<Paper>*/}
      <Grid container direction={"row"} spacing={1}>
        <Grid item>
          <Button className={classes.button} variant={"contained"} onClick={() => setExpandAll(!expandAll)}>
            {expandAll ? "Collapse All" : "Expand All Rows"}
          </Button>
        </Grid>
        <Grid item>
          {downloadable && (
            <div>
              <Button className={classes.button} variant={"contained"} onClick={handleDownloadButtonClick}>
                Download Selection
              </Button>
              <a ref={linkRef} style={{ display: "none" }}>
                Download URL
              </a>
            </div>
          )}
        </Grid>
      </Grid>
      <TableContainer>
        <TablePagination
          component="div"
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
        <Table aria-label="collapsible-table" size={"small"}>
          <TableHead>
            <TableRow>
              <TableCell style={{ borderBottom: "none", paddingBottom: "0px" }}></TableCell>
              {selectable && (
                <TableCell style={{ borderBottom: "none", paddingBottom: "0px" }} padding="checkbox">
                  <Checkbox data-testid="select-all" checked={selectAll} onChange={handleAllSelect} />
                </TableCell>
              )}
              <TableCell style={{ borderBottom: "none", paddingBottom: "0px" }}>Scenario&nbsp;Name</TableCell>
              <Hidden xsDown>
                <TableCell style={{ borderBottom: "none", paddingBottom: "0px" }}>Version</TableCell>
              </Hidden>
              <Hidden smDown>
                <TableCell style={{ borderBottom: "none", paddingBottom: "0px" }}>Time&nbsp;Horizon</TableCell>
              </Hidden>
            </TableRow>

            <TableRow>
              <TableCell style={{ paddingTop: "0px" }} />
              {selectable && <TableCell style={{ paddingTop: "0px" }} />}
              <TableCell colSpan={3} style={{ paddingTop: "0px" }}>
                <ScenarioTableSearchField setSearch={props.setSearch} reference={props.searchRefs.scenarioName} />
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody style={{
            position: 'relative', 
          }}>
            {<Backdrop style={{position: "absolute", zIndex: 1, opacity: 0.3}} open={props.loading}><CircularProgress /></Backdrop>}
            {scenarios.map((scenario) => (
              <ScenarioRow
                key={`${scenario.scenario_id}-${scenario.version}`}
                className={classes.styledRow}
                {...scenario}
                expandAll={expandAll}
                selectable={selectable}
                selected={
                  selected.filter((x) => x.scenario_id === scenario.scenario_id && x.version === scenario.version)
                    .length > 0
                }
                onSelect={handleRowSelect}
              />
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={count}
          page={page}
          rowsPerPage={rowsPerPage}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
        />
      </TableContainer>
      {/*</Paper>*/}
    </React.Fragment>
  );
}
