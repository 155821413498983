const SOLUTION_ENUMS = {
    costFunctions: {
        JB1: "JB1",
        SA1: "SA1",
        WX1: "WX1",
        SM1: "SM1",
        SM2: "SM2",
        SM3: "SM3",
        TR1: "TR1",
    },

    vehicleModels: {
        PM: "PM: Point-Mass Model",
        ST: "ST: Single-Track Model",
        KS: "KS: Kinematic Single-Track Model",
        MB: "MB: Multi-Body Model",
    },

    vehicleTypes: {
        1: "1: FORD_ESCORT",
        2: "2: BMW_320i",
        3: "3: VW_VANAGON",
    },
};

export default SOLUTION_ENUMS;