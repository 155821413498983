import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import React from "react";

function Video(props) {
  /**
   * YouTube video id
   * e.g. https://www.youtube.com/watch?v=QwwouEPh9jg
   * id being "QwwouEPh9jg" part
   */

  // YouTube video id, (e.g. https://www.youtube.com/watch?v=QwwouEPh9jg)
  return (
    <React.Fragment>
      <iframe
        width="100%"
        height="300px"
        src={`https://www.youtube.com/embed/${props.id}`}
        title="Video Player"
        frameborder="0"
        allow="
        accelerometer;
        autoplay;
        clipboard-write;
        encrypted-media;
        gyroscope;
        picture-in-picture"
        allowFullScreen
      />
    </React.Fragment>
  );
}

export const renderVideo = (video) => {
  return (
    <Grid container justifyContent="center">
      <Grid item xs={12}>
        <Typography gutterBottom variant="h6">
          {video.title}
        </Typography>
      </Grid>

      <Grid item xs={12} style={{textAlign: "center"}}>
        <Video id={video.id}/>
      </Grid>
    </Grid>
  );
};