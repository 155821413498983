import React from 'react';
import UserSubmissionsTableContainer from '../../../../components/user-submissions/user-submissions-table-container';
import SolutionService from '../../../../services/api/solution-service';
import StorageService from '../../../../services/local-storage/storage-service';

export default function AllSubmissions() {

  const [loading, setLoading] = React.useState(true);
  const [fetch, setFetch] = React.useState(false);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  // solutions
  const [solutions, setSolutions] = React.useState([]);

  // user
  const user = StorageService.getUserInformation();

  React.useEffect(() => {
    const query = {
      page: page+1,
      page_size: rowsPerPage,
      user__id: user.id
    };

    SolutionService.getSolutions(query).then((response) => {
      setCount(response.data.count);
      setSolutions(response.data.results);
      setLoading(false);
    }).catch((error) => {
      return error;
    });
  }, [page, rowsPerPage, fetch]);

  const containerProps = {
    loading: loading,
    fetch: fetch,
    solutions: solutions,
    count: count,
    page: page,
    rowsPerPage: rowsPerPage,
    setLoading: setLoading,
    setFetch: setFetch,
    setPage: setPage,
    setRowsPerPage: setRowsPerPage
  }

  return (
    <React.Fragment>
      <UserSubmissionsTableContainer {...containerProps} />
    </React.Fragment>
  )
}