import React from "react";
import Moment from "react-moment";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Checkbox,
  Paper,
  TablePagination,
  CircularProgress,
  Grid,
  Box,
  IconButton,
  Collapse,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import baseStyles from "../../css/const";
import ErrorCell from "../error-cell";
import ActivityService from "../../services/api/activity-service";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import CustomStatusBox from "../misc/custom-status-box";

const tableHeadsDefault = [
  "Benchmark",
  "Status",
  "Public",
  "Cost",
  "Computation time [s]",
  "Method",
  "Algorithm",
  "Code URL",
  "Created ⯆",
];

const tableHeadsDocker = [
  "Image URL",
  "Status",
  "Created",
  "Public",
  "Top 1 Solutions",
  "Solved Scenarios",
  "Failed scenarios",
  "Unsolved scenarios",
];

const DefaultTableRow = (props) => {
  return (
    <React.Fragment>
      <TableRow hover aria-checked={props.isSelected} role="checkbox" selected={props.isSelected} key={props.id}>
        <TableCell padding="checkbox">
          <Checkbox
            data-testid={`checkbox-${props.id}`}
            onClick={(event) => props.handleSelectClick(event, props.id, props.benchmark_id)}
            checked={props.isSelected}
          />
        </TableCell>
        <TableCell style={{ padding: 0 }} component={Link} to={`/solutions/ranking/${props.benchmark_id}`}>
          {props.benchmark_id}
        </TableCell>
        {["success", "pending"].includes(props.evaluation_status) ? (
          <TableCell style={{ padding: 0 }}>
            <CustomStatusBox status={props.evaluation_status === 'pending' ? 'warning' : props.evaluation_status}/>
          </TableCell>
        ) : (
          <ErrorCell>{props.error_message}</ErrorCell>
        )}
        <TableCell style={{ padding: 0 }}>{props.public ? "Yes" : "No"}</TableCell>
        <TableCell style={{ padding: 0 }}>{props.cost ? props.cost.toFixed(3) : ""}</TableCell>
        <TableCell style={{ padding: 0 }}>{props.computation_time ? props.computation_time.toFixed(3) : ""}</TableCell>
        <TableCell style={{ padding: 0 }}>{props.method || ""}</TableCell>
        <TableCell style={{ padding: 0 }}>{props.algorithm || ""}</TableCell>
        <TableCell style={{ padding: 0 }} component="a" target="_blank" rel="noopener noreferrer" href={props.code_url}>
          {props.code_url || ""}
        </TableCell>
        <TableCell style={{ padding: 0 }}>
          <Moment format="YYYY-MM-DD hh:mm:ss">{props.created}</Moment>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
};

const DockerTableRow = (props) => {
  const [open, setOpen] = React.useState(false);
  const [errors, setErrors] = React.useState([]);

  React.useEffect(() => {
    ActivityService.getActivityDockerSubmissionErrors(props.id, props.user_id)
      .then((response) => {
        setErrors(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const handleIconClick = (event) => {
    // button is not rendered for hidden challenges
    event.preventDefault();
    setOpen(!open); // open or close the collapsible component
  };

  const evaluationStatus = props.evaluation_status === 'pending' ? 'warning' : (
    props.evaluation_status === 'running' ? 'info' : props.evaluation_status
  );

  return (
    <React.Fragment>
      <TableRow hover aria-checked={props.isSelected} role="checkbox" selected={props.isSelected}>
        <TableCell padding="checkbox">
          <Checkbox
            data-testid={`checkbox-${props.id}`}
            onClick={(event) => props.handleSelectClick(event, props.id, props.benchmark_id)}
            checked={props.isSelected}
          />
        </TableCell>
        <TableCell style={{ padding: 0 }}>{props.docker_url}</TableCell>
        {["success", "pending", "running"].includes(props.evaluation_status) ? (
          <TableCell style={{ padding: 0 }}>
            <CustomStatusBox status={evaluationStatus}/>
          </TableCell>
        ) : (
          <ErrorCell>{props.error_message}</ErrorCell>
        )}
        <TableCell style={{ padding: 0 }}>
          <Moment format="YYYY-MM-DD hh:mm:ss">{props.created}</Moment>
        </TableCell>
        <TableCell style={{ padding: 0 }}>{props.public ? "Yes" : "No"}</TableCell>
        <TableCell style={{ padding: 0 }}>{props.best_solution_count}</TableCell>
        <TableCell style={{ padding: 0 }}>{props.solved_scenario_count}</TableCell>
        <TableCell>
          {errors.length}
          {errors.length !== 0 ? (
            <IconButton key={props.id} aria-label="expand row" size="small" onClick={handleIconClick}>
              {open ? <KeyboardArrowUpIcon id={props.id} /> : <KeyboardArrowDownIcon id={props.id} />}
            </IconButton>
          ) : null}
        </TableCell>
        <TableCell style={{ padding: 0 }}>{props.remaining_scenario_count}</TableCell>
      </TableRow>
      {open ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#f5f5f7" }} colSpan={9}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom>
                  Error statistics of failed solutions
                </Typography>
              </Box>
              <TableContainer component="div">
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Details</TableCell>
                      <TableCell style={{ textAlign: "right" }}>Occurrences</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    {errors.map((detail) => (
                      <TableRow>
                        <TableCell>{"Error message: " + detail["error_message"]}</TableCell>
                        <TableCell style={{ textAlign: "right" }}>{detail["count"]}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
};

export default function UserSubmissionsTable(props) {
  const tableHeads = props.docker ? tableHeadsDocker : tableHeadsDefault;

  return (
    <React.Fragment>
      <Paper>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell padding="checkbox">
                  <Checkbox
                    checked={props.selectAll && !(props.excluded.length > 0)}
                    onChange={props.handleSelectAllClick}
                  />
                </TableCell>
                {tableHeads.map((tableHead) => (
                  <TableCell key={tableHead} style={{ padding: "0" }}>
                    {tableHead}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {props.loading && (
                <TableRow>
                  <TableCell colSpan={tableHeads.length + 1}>
                    <div style={{ textAlign: "center" }}>
                      <CircularProgress data-testid="user-submission-progress" />
                    </div>
                  </TableCell>
                </TableRow>
              )}

              {!props.loading &&
                props.solutions.map((row, index) => {
                  const isSelected =
                    props.selected.findIndex((x) => x.id === row.id) !== -1 ||
                    (props.selectAll && props.excluded.findIndex((x) => x.id === row.id) === -1);
                  const propsRow = {
                    handleSelectClick: props.handleSelectClick,
                    isSelected: isSelected,
                    ...row,
                    id: row.id, // had to add id to the props again, why?
                  };

                  return props.docker ? (
                    <DockerTableRow key={row["id"]} {...propsRow} />
                  ) : (
                    <DefaultTableRow key={row["id"]} {...propsRow} />
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          page={props.page}
          count={props.count}
          rowsPerPage={props.rowsPerPage}
          onChangePage={props.handlePageChange}
          onChangeRowsPerPage={props.handleRowsPerPageChange}
        />
      </Paper>
    </React.Fragment>
  );
}
