import React from "react";
import CountUp from "react-countup";
import { Card, CardContent, Link, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
    textAlign: "left",
    color: theme.palette.text.primary,
    margin: "auto",
    marginTop: "20px",
    height: "100%",
    backgroundColor: theme.palette.primary.backgroundColor,
    // boxShadow: "2px 5px 30px -8px rgba(0,0,0,0.2)",
  },
}));

export default function StatisticCard(props) {
  const classes = useStyles();
  const link = props.to;
  if (link) {
    return (
      <Link href={link}>
        <Card elevation={2} className={classes.card} variant={"outlined"}>
          <CardContent>
            <div style={{ textAlign: "center" }}>
              <CountUp
                start={0}
                end={props.count}
                duration={2}
                style={{
                  fontSize: "min(5vw, 25pt)",
                  fontFamily: "Rubik",
                  margin: "0 auto",
                }}
              ></CountUp>
            </div>

            <Typography align="center" style={{ fontSize: "min(3vw, 11pt)" }}>
              {props.text}
            </Typography>
          </CardContent>
        </Card>
      </Link>
    );
  } else {
    return (
      <Card elevation={2} className={classes.card} variant={"outlined"}>
        <CardContent>
          <div style={{ textAlign: "center" }}>
            <CountUp
              start={0}
              end={props.count}
              duration={3}
              style={{
                fontSize: "min(5vw, 25pt)",
                fontFamily: "Rubik",
                margin: "0 auto",
              }}
            ></CountUp>
          </div>

          <Typography align="center" style={{ fontSize: "min(3vw, 11pt)" }}>
            {props.text}
          </Typography>
        </CardContent>
      </Card>
    );
  }
}
