import React from "react";
import {
  Grid,
  Collapse,
  Avatar,
  makeStyles,
  Typography,
  TableContainer,
  Table,
  TableBody,
  TableRow,
  TableCell,
} from "@material-ui/core";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";

const team = {
  "Prof. Dr.-Ing. Matthias Althoff": {
    email: "mailto:althoff@tum.de",
    profile: "https://www.ce.cit.tum.de/cps/members/prof-dr-ing-matthias-althoff/",
    role: "Advisor"
  },

  "Hanna Krasowski": {
    email: "mailto:krasowski@berkeley.edu",
    profile: "https://hanna.krasowski.io/",
    role: "Principal Developer"
  },
  "Marlon Mueller": {
    email: "mailto:marlon.muller@tum.de",
    //profile: "https://github.com/evilpoke",
    role: "Developer"
  },

  "Stefan Schärdinger": {
    email: "mailto:stefan.schaerdinger@tum.de",
    profile: "https://github.com/evilpoke",
    role: "Developer"
  },




  "Bruno Maione": {
    email: "mailto:",
    profile: "https://br.linkedin.com/in/bruno-maione?trk=people-guest_people_search-card",
    role: "Past Developer"
  },
  "Fabian Thomas-Barein": {
    email: "mailto:",
    //profile: "",
    role: "Past Developer"
  }
};

export default function TeamPage() {
  // set document title
  const [title, setTitle] = useDocTitle("Team");

  // setup tracker after setting the document title
  useTracker();

  return (
    <DefaultLayout>
      <Typography variant="h2" style={{ marginBottom: "10px" }}>
        Team
      </Typography>

      <TableContainer>
        <Table>
          <TableBody>
            {Object.keys(team).map((key, val) => {
              return (
                <TableRow key={key}>
                  <TableCell>
                    <Typography>{key}</Typography>
                  </TableCell>
                  <TableCell>
                    <a href={team[key].profile}>Profile Page</a>
                  </TableCell>
                  <TableCell>
                    <a href={team[key].email.replace("[at]", "@")}>{team[key].email.replace("mailto:", "")}</a>
                  </TableCell>
                  <TableCell>
                    {team[key].role}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
    </DefaultLayout>
  );
}
