import React from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import axios from "axios";
import SlackAppService from "../services/slack-app";
import NotFound from "../pages/static/errors/not-found-error";

export default function ErrorHandler({ children }) {
  const location = useLocation();
  const state = location.state;

  React.useEffect(() => {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (400 <= error.response.status <= 599 && error.response.status !== 401) {
          const data = {
            date: moment().format("MMMM Do YYYY, h:mm:ss a"),
            statusCode: "HTTP Error",
            stack: `*${error.response.status}*\n${error.response.statusText}\n${error.response.config.url}`,
          };
          SlackAppService.log(data);
          window.location = '/api-error';
        }

        return Promise.reject(error);
      }
    );
  });

  if (state) {
    switch (state.errorCode) {
      case "InvalidURL":
        return <NotFound />;

      default:
        return children;
    }
  }

  return children;
}
