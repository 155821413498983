import { Button } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import React from 'react';


export default function CustomSnackbarContent(props) {
  const text = props.text ? props.text : 'Update';
  let _text = '';
  if (props.status === "success") {
    _text = `${text} was successful`;
  } else if (props.status === "warning") {
    _text = `Some of the ${text.toLowerCase()}(s) have failed`;
  } else {
    _text = `${text} has failed`;
  }
  return (
    <React.Fragment>
      <Alert
        severity={props.status}
        action={
          <React.Fragment>
            <Button size="small" onClick={props.handleDialogOpen}>
              RESULTS
            </Button>
            <Button size="small" onClick={props.handleSnackbarClose}>
              CLOSE
            </Button>
            {!props.refresh && <Button size="small" onClick={() => window.location.reload()}>
              REFRESH
            </Button>}
          </React.Fragment>
        }
      >
        {_text}
      </Alert>
    </React.Fragment>
  );
}