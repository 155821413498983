import axios from "axios";
import TokenService from "./api/token-service";
import StorageService from "./local-storage/storage-service";


// axios instance without authentication
const axiosDefaultInstance = axios.create();

// axios instance with authentication
const axiosAuthInstance = axios.create();
axiosAuthInstance.interceptors.request.use(
  (config) => {
    const accessToken = StorageService.getAccessToken();

    if (accessToken) {
      config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosAuthInstance.interceptors.response.use(
  (response) => {
    return response;
  },

  async function (error) {
    const originalRequest = error.config;

    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      const refreshToken = StorageService.getRefreshToken();

      if (StorageService.isTokenExpired("REFRESH")) {
        StorageService.clearAll();
        window.location = "/signin";
        return Promise.reject(error);
      }

      return TokenService.refreshToken(refreshToken)
        .then((resp) => {
          // refresh successful
          const accessToken = resp;

          // save new access token
          StorageService.clearToken("ACCESS");
          StorageService.storeToken(accessToken, "ACCESS");
          axios.defaults.headers.common[
            "Authorization"
          ] = `Bearer ${accessToken}`;

          return axiosAuthInstance(originalRequest);
        })
        .catch((error) => {
          return Promise.reject(error);
        });
    }

    return Promise.reject(error);
  }
);

// replace below function with ENUM
export default function getInstance(instanceType = "default") {
  if (instanceType === "default") {
    return axiosDefaultInstance;
  } else {
    return axiosAuthInstance;
  }
}
