import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Backdrop,
  Box,
  CircularProgress,
  Grid,
  Hidden,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import { Link } from "react-router-dom";
import Moment from "react-moment";
import InfoIcon from "@material-ui/icons/Info";
import Collapse from "@material-ui/core/Collapse";
import BenchmarkIdExplainer from "../../benchmark/benchmark-id-explainer";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    marginTop: "10px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    paddingTop: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  img: {
    maxWidth: "100%",
    height: "auto",
    maxHeight: "400px",
  },
  title: {
    fontWeight: "500",
    color: "#404040",
    fontSize: "1.7em",
    textAlign: "left",
    fontFamily: "Roboto",
    // letterSpacing: 1.5
  },
}));

export default function BenchmarkRankingsTable(props) {
  const classes = useStyles();
  const [showBenchmarkHelp, setShowBenchmarkHelp] = React.useState(false);

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid container overflow="scroll" direction="column" alignItems="stretch" justify="center">
          <Grid container direction="row" alignItems="stretch" justify="center" spacing={2}>
            <Grid item xs={12} md={7}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Typography className={classes.title} style={{ paddingLeft: "20px" }}>
                    Benchmark ranking
                    <br />
                    <Box fontFamily="monospace" display="inline">
                      {props.benchmarkId}
                    </Box>
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    variant="contained"
                    onClick={() => setShowBenchmarkHelp(!showBenchmarkHelp)}
                    style={{
                      marginLeft: "10px",
                      marginRight: "auto",
                      display: "block",
                      alignItems: "left",
                      fontSize: "medium",
                    }}
                  >
                    {showBenchmarkHelp ? "Hide" : "Show"} benchmark ID details &nbsp;
                    <InfoIcon />
                  </IconButton>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={5}
              item
              style={{
                marginBottom: "10px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {props.bestSolution && props.bestSolution.current && props.bestSolution.current.gif ? (
                <div>
                  <Typography
                    style={{
                      marginLeft: "auto",
                      marginRight: "auto",
                      display: "block",
                      align: "center",
                      fontSize: "small",
                      color: "gray",
                    }}
                  >
                    Best solution:
                  </Typography>
                  <img
                    alt='Best Solution'
                    className={classes.img}
                    key={props.bestSolution.current.gif}
                    src={props.bestSolution.current.gif}
                  />
                </div>
              ) : null}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Collapse in={showBenchmarkHelp}>
              <BenchmarkIdExplainer benchmarkId={props.benchmarkId} />
            </Collapse>
          </Grid>

          <Grid item>
            <TableContainer>
              <TablePagination
                component="div"
                count={props.count}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                onChangePage={props.onChangePage}
                onChangeRowsPerPage={props.onChangeRowsPerPage}
              />
              <Table>
                <TableHead>
                  {/** table head */}
                  <TableRow>
                    <TableCell>Rank</TableCell>
                    <TableCell>User</TableCell>

                    {/** hidden for small screens */}
                    <Hidden smDown>
                      <TableCell>Country</TableCell>
                      <TableCell>Organization</TableCell>
                      <TableCell>Method</TableCell>
                      <TableCell>Algorithm</TableCell>
                    </Hidden>

                    <TableCell>Date</TableCell>

                    {/** hidden for small screens */}
                    <Hidden smDown>
                      <TableCell>CPU</TableCell>
                    </Hidden>

                    {/** hidden for extra small screens */}
                    <Hidden xsDown>
                      <TableCell>Computation Time [s]</TableCell>
                    </Hidden>

                    <TableCell>Cost</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody
            style={{
              position: "relative",
            }}
          >
            {
              <Backdrop style={{ position: "absolute", zIndex: 1, opacity: 0.3 }} open={props.loading}>
                <CircularProgress />
              </Backdrop>
            }
                  {/** table body */}
                  {props.solutions.map((solution, idx) => {
                    return (
                      <TableRow
                        component={Link}
                        to={`/solutions/${solution.id}/details`}
                        hover
                        tabIndex={-1}
                        key={solution.id}
                        style={{ textDecoration: "none" }}
                      >
                        <TableCell component="th" scope="row">
                          {props.rowsPerPage * props.page + idx + 1}
                        </TableCell>
                        <TableCell>{solution.user.username}</TableCell>
                        <Hidden smDown>
                          <TableCell>{solution.user.country}</TableCell>
                          <TableCell>{solution.user.institution}</TableCell>
                          <TableCell>{solution.method}</TableCell>
                          <TableCell>{solution.algorithm}</TableCell>
                        </Hidden>
                        <TableCell>
                          <Moment format="YYYY/MM/DD" date={solution.created} />
                        </TableCell>
                        <Hidden smDown>
                          <TableCell>{solution.cpu && solution.cpu.name}</TableCell>
                        </Hidden>
                        <Hidden xsDown>
                          <TableCell>{solution.computation_time && solution.computation_time.toFixed(3)}</TableCell>
                        </Hidden>
                        <TableCell>{solution.cost.toFixed(2)}</TableCell>
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>

              <TablePagination
                component="div"
                count={props.count}
                page={props.page}
                rowsPerPage={props.rowsPerPage}
                onChangePage={props.onChangePage}
                onChangeRowsPerPage={props.onChangeRowsPerPage}
              />
            </TableContainer>
          </Grid>
        </Grid>
      </Paper>
    </React.Fragment>
  );
}
