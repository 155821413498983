import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import InfoIcon from "@material-ui/icons/Info";


export default function CustomStatusBox(props) {
  const useStyles = makeStyles((theme) => ({
    paper: {
      backgroundColor: theme.palette[props.status].main,
      width: theme.spacing(3),
      height: theme.spacing(3),
      margin: theme.spacing(0)
    }
  }));
  const classes = useStyles();

  return (
    <React.Fragment>
      <Paper className={classes.paper} style={props.style} elevation={0} >
        {props.withIcon === true ? <InfoIcon style={{"opacity": 0.4}}/> : null}
      </Paper>
    </React.Fragment>
  );
}
