import { Paper } from "@material-ui/core";
import React from "react";
import ScenarioFilter from "../../components/scenario/scenario-filter";
import ScenarioTable from "../../components/scenario/scenario-table";
import ScenarioService from "../../services/api/scenario-service";

import baseStyles from "../../css/const";

export default function ScenariosContainer(props) {
  const classes = baseStyles();

  // table states
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [scenarios, setScenarios] = React.useState([]);
  const [scenarioIds, setScenarioIds] = React.useState([]);
  const [count, setCount] = React.useState(0);

  const [search, setSearch] = React.useState(false);
  const [reset, setReset] = React.useState(false);
  const [apply, setApply] = React.useState(false);
  const [loading, setLoading] = React.useState(false); //set as true if using static
  const [scenariosLoading, setScenariosLoading] = React.useState(true);
  const isPublic = props.public;

  const searchRefs = {
    scenarioName: React.useRef(""),
  };

  const values = {
    version: React.useRef("2022a"), // single select
    public: React.useRef(), // Boolean
    tags: React.useRef([]), // multiple select
    sources: React.useRef([]), // multiple select
    behaviorType: React.useRef(null), // single select
    obstacleTypes: React.useRef([]), // multiple select
    goalTypes: React.useRef([]), // multiple select
    timeHorizon: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    initialVelocity: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    initialVelocities: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    staticObstacleCount: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    dynamicObstacleCount: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    egoVehicleCount: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    goalRegionCount: {
      min: React.useRef(0), 
      max: React.useRef(0),
    },
  };

  const options = {
    version: React.useRef([]),
    sources: React.useRef([]),
    tags: React.useRef([]),
    behaviorType: React.useRef([]),
    obstacleTypes: React.useRef([]),
    goalTypes: React.useRef([]),
    timeHorizon: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    initialVelocity: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    initialVelocities: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    staticObstacleCount: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    dynamicObstacleCount: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    egoVehicleCount: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    goalRegionCount: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
  };

  // fill out query parameters
  const setParams = (values, searches) => {
    const join = (arr) => (arr.length === 0 ? null : arr.join(","));
    const params = {
      activities__id: props.activityId,
      page: page + 1,
      page_size: rowsPerPage,
      version: values.version.current,
      public: values.public.current,
      metadata__behavior_type: values.behaviorType.current,
      metadata__sources: join(values.sources.current),
      metadata__tags: join(values.tags.current),
      metadata__goal_types: join(values.goalTypes.current),
      metadata__obstacle_types: join(values.obstacleTypes.current),
      metadata__time_horizon__gte: values.timeHorizon.min.current,
      metadata__time_horizon__lte: values.timeHorizon.max.current,
      metadata__max_initial_velocity__gte: values.initialVelocity.min.current,
      metadata__max_initial_velocity__lte: values.initialVelocity.max.current,
      //metadata__max_initial_velocities__gte: values.initialVelocities.min.current,
      //metadata__max_initial_velocities__lte: values.initialVelocities.max.current,
      metadata__static_obstacle_count__gte: values.staticObstacleCount.min.current,
      metadata__static_obstacle_count__lte: values.staticObstacleCount.max.current,
      metadata__dynamic_obstacle_count__gte: values.dynamicObstacleCount.min.current,
      metadata__dynamic_obstacle_count__lte: values.dynamicObstacleCount.max.current,
      metadata__ego_vehicle_count__gte: values.egoVehicleCount.min.current,
      metadata__ego_vehicle_count__lte: values.egoVehicleCount.max.current,
      metadata__max_goal_region__gte: values.goalRegionCount.min.current,
      metadata__max_goal_region__lte: values.goalRegionCount.max.current,
      scenario_id__icontains: searches.scenarioName.current,
    };

    return params;
  };

  // getting the answer from the api (if the setParams has been set before! )
  React.useEffect(() => {
    const params = props.activityId ? { activities__id: props.activityId } : null;
    ScenarioService.getScenariosSummary(params)
      .then((response) => {
        options.version.current = response.data.versions;
        options.sources.current = response.data.sources;
        options.tags.current = response.data.tags;
        options.behaviorType.current = response.data.behavior_types;
        options.obstacleTypes.current = response.data.obstacle_types;
        options.goalTypes.current = response.data.goal_types;
        options.timeHorizon.max.current = Math.ceil(response.data.max_time_horizon);
        options.initialVelocity.max.current = Math.ceil(response.data.max_initial_velocity);
        //options.initialVelocities.max.current = Math.ceil(response.data.max_initial_velocities);
        options.staticObstacleCount.max.current = Math.ceil(response.data.max_static_obstacle_count);
        options.dynamicObstacleCount.max.current = Math.ceil(response.data.max_dynamic_obstacle_count);
        options.egoVehicleCount.max.current = Math.ceil(response.data.max_ego_vehicle_count);
        options.goalRegionCount.max.current = Math.ceil(response.data.max_goal_region);

        // set max and min values of range sliders
        values.timeHorizon.max.current = Math.ceil(response.data.max_time_horizon);
        values.initialVelocity.max.current = Math.ceil(response.data.max_initial_velocity);
        values.staticObstacleCount.max.current = Math.ceil(response.data.max_static_obstacle_count);
        values.dynamicObstacleCount.max.current = Math.ceil(response.data.max_dynamic_obstacle_count);
        values.egoVehicleCount.max.current = Math.ceil(response.data.max_ego_vehicle_count);
        values.goalRegionCount.max.current = Math.ceil(response.data.max_goal_region);

        setLoading(false);

        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }, []);

  React.useEffect(() => {
    if (isPublic !== undefined) {
      values.public.current = isPublic;
    } else {
      values.public.current = true;
    }
    handleApplyButtonClick();
  }, [isPublic]);

  // get all scenari Ids
  React.useEffect(() => {
    let params = setParams(values, searchRefs);

    if (!loading) {
      ScenarioService.getAllScenarioIds(params)
        .then((response) => {
          setScenarioIds(response.data.scenario_ids);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [loading, apply, reset]);

  // get scenarios
  React.useEffect(() => {
    let params = setParams(values, searchRefs);
    console.log('DEBUG: TAGTAG')
    console.log(params)
    if (!loading) {
      ScenarioService.getScenarios(params)
        .then((response) => {
          setCount(response.data.count);
          setScenarios(response.data.results);
          setScenariosLoading(false);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [loading, apply, reset, page, rowsPerPage]);

  // search filters activated
  React.useEffect(() => {
    if (search) {
      const params = setParams(values, searchRefs);

      ScenarioService.getScenarios(params)
        .then((response) => {
          setPage(0);
          setCount(response.data.count);
          setScenarios(response.data.results);
          setSearch(false);
          setScenariosLoading(false);
        })
        .catch((error) => {
          return error;
        });
    }
  }, [search]);

  const handleApplyButtonClick = () => {
    setPage(0);
    setApply(!apply);
    setScenariosLoading(true);
  };

  const handleResetButtonClick = () => {
    for (const key in values) {
      if (Array.isArray(values[key].current)) {
        // array
        values[key].current = [];
      } else if (typeof values[key].current === "string") {
        // string
        values[key].current = null;
      } else if (values[key].current === null) {
        values[key].current = null;
      } else if (typeof values[key].current === "boolean") {
        values[key].current = true;
      } else {
        values[key].min.current = options[key].min.current;
        values[key].max.current = options[key].max.current;
      }
    }
    setPage(0);
    setRowsPerPage(10);
    setReset(!reset);
    setScenariosLoading(true);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setScenariosLoading(true);
  };

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    setScenariosLoading(true);
  };

  if (loading) {
    return <div style={{ display: "none" }} />;
  } else { // this whole if was commented (to make it work in static)
  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <ScenarioFilter
          values={values}
          options={options}
          showPublicFilter={props.showPublicFilter}
          applyClick={handleApplyButtonClick}
          resetClick={handleResetButtonClick}
        />
        <ScenarioTable
          selectable={props.selectable}
          downloadable={props.downloadable}
          loading={scenariosLoading} //scenariosLoading was here before (adjust after is working) - false if want to use static
          selected={props.selected}
          setSelected={props.setSelected}
          page={page}
          rowsPerPage={rowsPerPage}
          scenarios={scenarios}
          scenarioIds={scenarioIds}
          count={count}
          handlePageChange={handlePageChange}
          handleRowsPerPageChange={handleRowsPerPageChange}
          setSearch={setSearch}
          searchRefs={searchRefs}
        />
      </Paper>
    </React.Fragment>
  );
  }
}
