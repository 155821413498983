import React from "react";
import Select from "@material-ui/core/Select";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import Slider from "@material-ui/core/Slider";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Switch from "@material-ui/core/Switch";
import Button from "@material-ui/core/Button"
import IconButton from "@material-ui/core/IconButton";
import Collapse from "@material-ui/core/Collapse";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import baseStyles from "../../css/const";
import SCENARIO_ENUMS from './scenario-enums';
import SOLUTION_ENUMS from "../solution/solution-enums";
import {Divider} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  select: {
    width: '100%',
    minHeight: '37px'
  },

  chips: {
    display: "flex",
    maxWidth: '100%'
  },

  rangeSlider: {
    padding: "10px",
  },

  typography: {
    fontSize: "14px",
    marginBottom: "0px"
  },

  button: {
    marginRight: "10px",
    color: "white",
    backgroundColor: theme.palette.primary.main,
    "&:hover": {
      backgroundColor: theme.palette.primary.dark,
    }
  },
}));

function ToggleFilter(props) {

  const classes = useStyles();

  // properties
  const reference = props.reference;

  // states
  const [value, setValue] = React.useState(props.reference.current);

  React.useEffect(() => {
    reference.current = value;
  }, [value])

  React.useEffect(() => {
    setValue(reference.current);
  }, [reference.current])

  // handler
  const handler = (event) => {
    setValue(!value);
  }

  return (
    <Grid item>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} md={4}>
          <Typography className={classes.typography}>{props.label}</Typography>
        </Grid>
        <Grid item xs={7} md={8}>
          <Switch
            checked={value}
            onChange={handler}
            color="primary"
            inputProps={{ 'aria-label': 'primary checkbox'}}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

function SelectFilter(props) {

  const classes = useStyles();

  // properties
  const options = props.options;
  const reference = props.reference;

  // states
  const [value, setValue] = React.useState(props.reference.current);

  React.useEffect(() => {
    reference.current = value;
  }, [value])

  if (value != props.reference.current) {
    setValue(props.reference.current);
  }

  // handler
  const handler = (event) => {
    if (reference) {
      reference.current = event.target.value;
    }
    setValue(event.target.value);
    
  }

  const menuProps = {
    getContentAnchorEl: null,
    anchorOrigin: {
      vertical: "bottom",
      horizontal: "left",
    },
  }

  const mapEnum = (props, key) => {
    if ('scenarioId' in props) {
      return SCENARIO_ENUMS[props.scenarioId][key]
    } else if ('solutionId' in props) {
      return SOLUTION_ENUMS[props.solutionId][key]
    } else {
      return key 
    }
  }

  const render = (selected) => {

    if (selected.length === 0) {
      return <em style={{paddingTop: '25px', paddingBottom: '25px'}}>Nothing selected</em>
    }
    return <div className={classes.chips}>
      {selected.map((val) => (
        <Chip size='small' key={val} label={val}></Chip>
      ))}
    </div>
  }
  return (
    <Grid item>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={5} md={4}>
          <Typography className={classes.typography}>{props.label}</Typography>
        </Grid>
        <Grid item xs={7} md={7}>
          <Select
            data-testid={`${props.id}`}
            multiple={props.multiple}
            renderValue={props.multiple ? render : null}
            displayEmpty
            className={classes.select}
            MenuProps={menuProps}
            value={value}
            onChange={handler}
            
          >
            <MenuItem value={null} disabled={props.multiple ? true : false}>
              <em>Nothing selected</em>
            </MenuItem>
            {options.current.map((x) => (<MenuItem key={x} value={x}>{
              mapEnum(props, x)
            }</MenuItem>))}
          </Select>
        </Grid>
      </Grid>
    </Grid>
  )
}

const CustomSlider = withStyles({
  root: {
    height: 1,
    padding: "15px 0",
  }
})(Slider);

function SlideFilter(props) {
  const classes = useStyles();

  // properties
  const reference = props.reference;
  const options = props.options;

  // states
  const [value, setValue] = React.useState([reference.min.current, reference.max.current]);

  // handler
  const handler = (event, newValue) => {
    if (reference) {
      reference.min.current = newValue[0];
      reference.max.current = newValue[1];
    }

    setValue(newValue);
  }

  if (props.reference.min.current !== value[0] || props.reference.max.current !== value[1]) {
    setValue([reference.min.current, reference.max.current]);
  }

  return (
    <Grid item>
      <Grid container direction="row" spacing={2} alignItems="center">
        <Grid item xs={12} sm={5} md={4}>
          <Typography className={classes.typography}>{props.label}</Typography>
        </Grid>
        <Grid item xs={12} sm={7} md={7}>
          <CustomSlider
            max={options.max.current}
            value={value}
            onChange={handler}
            valueLabelDisplay="auto"
          ></CustomSlider>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default function ScenarioFilter(props) {
  const classes = baseStyles();

  const options = props.options;
  let values = props.values;
  const applyClick = props.applyClick;
  const resetClick = props.resetClick;
  // Solution specific filters ==> cost functions, vehicle models, vehicle types
  let showSolutionSpecificFilters = props.showSolutionSpecificFilters;
  let showPublicFilter = props.showPublicFilter;

  const [solutionFiltersOpen, setSolutionFiltersOpen] = React.useState(true);
  const [scenarioFiltersOpen, setScenarioFiltersOpen] = React.useState(!showSolutionSpecificFilters)

  React.useEffect(() => {
    values = props.values;
  }, [props.values])

  return (
    <React.Fragment>
      <Grid container direction="column" justify="center" style={{paddingLeft: '5px', paddingRight: '5px'}}>
        { showSolutionSpecificFilters ?
        <React.Fragment>
            <Typography variant="h6" gutterBottom component="div"> 
              Benchmark Configuration 
              <IconButton aria-label="expand row" size="small" onClick={() => setSolutionFiltersOpen(!solutionFiltersOpen)}>
                            {solutionFiltersOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
              </IconButton>
            </Typography>

            <Collapse in={solutionFiltersOpen} timeout="auto" unmountOnExit>

            <SelectFilter
                solutionId="costFunctions"
                label="Cost&nbsp;Function"
                reference={values.costFunction}
                options={options.costFunction}
            />
            
            <SelectFilter
                solutionId="vehicleModels"
                label="Vehicle&nbsp;Model"
                reference={values.vehicleModel}
                options={options.vehicleModel}
            />
            
            <SelectFilter
                solutionId="vehicleTypes"
                label="Vehicle&nbsp;Type"
                reference={values.vehicleType}
                options={options.vehicleType}
            />
          
          </Collapse>
          <div style={{ paddingBottom: "30px"}}></div>
        </React.Fragment>
        : null }

        <Typography variant="h6" gutterBottom component="div"> 
          Scenario Parameters
          <IconButton aria-label="expand row" size="small" onClick={() => setScenarioFiltersOpen(!scenarioFiltersOpen)}>
                          {scenarioFiltersOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </Typography>

        <Collapse in={scenarioFiltersOpen} timeout="auto" unmountOnExit>
          { showPublicFilter ?
            <ToggleFilter
              label="Show Public Scenarios"
              reference={values.public}
            />
            : null }

          <SelectFilter
            label="Scenario&nbsp;Version"
            reference={values.version}
            options={options.version}
          />

          <SelectFilter
            multiple
            label="Data&nbsp;Source"
            reference={values.sources}
            options={options.sources}
          />

          <SelectFilter
            multiple
            scenarioId='tags'
            label="Scenario&nbsp;Tags"
            reference={values.tags}
            options={options.tags}
          />

          <SlideFilter
            label="Scenario&nbsp;Duration"
            reference={values.timeHorizon}
            options={options.timeHorizon}
          />

          <Divider style={{margin: "4px"}} />

          <SelectFilter
            scenarioId='behaviorTypes'
            label="Obstacle&nbsp;Behavior/Prediction Type"
            reference={values.behaviorType}
            options={options.behaviorType}
          />

          <SelectFilter
            multiple
            scenarioId='obstacleTypes'
            label="Obstacle&nbsp;Classes"
            reference={values.obstacleTypes}
            options={options.obstacleTypes}
          />

          <SlideFilter
            label="No.&nbsp;of&nbsp;Static&nbsp;Obstacles"
            reference={values.staticObstacleCount}
            options={options.staticObstacleCount}
          />

          <SlideFilter
            label="No.&nbsp;of&nbsp;Dynamic&nbsp;Obstacles"
            reference={values.dynamicObstacleCount}
            options={options.dynamicObstacleCount}
          />

          <Divider style={{margin: "4px"}} />

          <SlideFilter
            label="No.&nbsp;of&nbsp;Ego&nbsp;Vehicles"
            reference={values.egoVehicleCount}
            options={options.egoVehicleCount}
          />

          <SlideFilter
            label="Initial&nbsp;Velocity&nbsp;(m/s)"
            reference={values.initialVelocity}
            options={options.initialVelocity}
          />

          <SelectFilter
            multiple
            scenarioId='goalTypes'
            label="Goal&nbsp;Types"
            reference={values.goalTypes}
            options={options.goalTypes}
          />

          <SlideFilter
            label="Goal&nbsp;Regions&nbsp;per&nbsp;Planning&nbsp;Problem"
            reference={values.goalRegionCount}
            options={options.goalRegionCount}
          />
        </Collapse>
      </Grid>

       <Grid container direction={"row"} spacing={1} style={{marginTop: '10px'}}>
         <Grid item>
           <Button className={classes.button} variant={"contained"} onClick={applyClick}>Apply Filter</Button>
         </Grid>
         <Grid item>
           <Button className={`${classes.button} ${classes.buttonDelete}`} variant={"contained"} onClick={resetClick}>Reset Filter</Button>
         </Grid>
      </Grid>
    </React.Fragment>
  );
}
