import React from 'react';

export default function useParentWindow(iframeRef) {

  React.useEffect(() => {
    iframeRef.current.contentWindow.addEventListener('load', ()=>{
      var tags = iframeRef.current.contentWindow.document.getElementsByTagName('a');
      for (let tag of tags) {
        tag.target = '_blank';
      }
    })
  }, []);

}