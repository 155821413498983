import { Box, LinearProgress, Typography } from "@material-ui/core";
import React from "react";

const normalize = (value, start, end) => {
  return ((value - start) * 100) / (end - start + 1);
};

export default function ChallengeDateProgress(props) {
  const start = new Date(props.start).toUTCString();
  const end = new Date(props.end).toUTCString();

  const [progress, setProgress] = React.useState({ value: 0, counter: 0 });

  React.useEffect(() => {
    if (!props.loading) {
      const diff = normalize(Date.now(), props.start, props.end);
      const timer = setInterval(() => {
        setProgress((oldProgress) => {
          if (oldProgress.counter === 10) {
            clearInterval(timer);
            return { value: oldProgress.value, counter: 10 };
          }

          if (oldProgress.value >= 100) {
            clearInterval(timer);
            return { value: 100, counter: 10 };
          }

          return { value: Math.min(oldProgress.value + diff / 10, 100), counter: oldProgress.counter + 1 };
        });
      }, 200);

      return () => {
        clearInterval(timer);
      };
    }
  }, [props.loading]);

  return (
    <Box display="flex" alignItems="center">
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`Launch`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {start}
        </Typography>
      </Box>
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" value={progress.value} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">
          {`End`}
        </Typography>
        <Typography variant="body2" color="textSecondary">
          {end}
        </Typography>
      </Box>
    </Box>
  );
}
