import React from 'react';
import DefaultLayout from '../../components/layouts/default-layout';
import LargeLayout from '../../components/layouts/large-layout';
import NotebookFrame from '../../components/notebook-frame';
import useDocTitle from '../../hooks/use-doc-title';
import useTracker from '../../hooks/use-tracker';
import tutorials from '../../json/tutorials.json';

import { useHistory } from 'react-router-dom';


export default function Tutorial(props) {

  const history = useHistory();

  const tutorialName = props.match.params.tutorial;
  const tutorial = tutorials[tutorialName];

  // set document title
  const [title, setTitle] = useDocTitle((tutorial) ? tutorial.title : 'undefined');

  // setup tracker after setting the document title
  useTracker();

  if (tutorial === undefined) {
    history.replace(history.location.pathname, {
      errorCode: 'InvalidURL'

    })
  }



  return (
    <LargeLayout>
    {
      (tutorial)
      ? (<NotebookFrame title={tutorialName} src={tutorial.src} />)
      : null
    }
      
    </LargeLayout>
  )
  
}

// import React, { useLayoutEffect, useState } from 'react';
// import DefaultLayout from '../../components/layouts/default-layout';
// import useDocTitle from "../../hooks/use-doc-title";
// import useTracker from '../../hooks/use-tracker';
// import useParentWindow from '../../hooks/use-parent-window';


// function useWindowSize() {
//   const [size, setSize] = useState([0, 0]);
//   useLayoutEffect(() => {
//     function updateSize() {
//       setSize([window.innerWidth, window.innerHeight]);
//     }
//     window.addEventListener('resize', updateSize);
//     updateSize();
//     return () => window.removeEventListener('resize', updateSize);
//   }, []);
//   return size;
// }


// export default function CollisionChecker() {

//   // set document title
//   const [title, setTitle] = useDocTitle("Tutorial - Collision Checker");

//   // setup tracker after setting the document title
//   useTracker();

//   // create ref and change attributes
//   // const iframeRef = React.useRef();
//   // useParentWindow(iframeRef);


//   return (
//     <DefaultLayout>
//       <iframe
//         style={{margin: "0 auto", width: "100%", height: "100vh", overflow: "hidden"}}
//         id="frame0"
//         title="tutorial-notebook"
//         src="/tutorials/static_docs_tutorials_collision_checker.html"
//       ></iframe>
//     </DefaultLayout>
//   )
// }




