import React from 'react';
import ChallengeLeaderboardTable from '../../../components/challenge/challenge-leaderboard/challenge-leaderboard-table';


export default function ChallengeLeaderboard(props){

  return (
    <React.Fragment>
      <ChallengeLeaderboardTable id={props.id} hidden={props.hidden}/>
    </React.Fragment>
  );
  
}