import React from 'react';

import SolutionService from '../../../services/api/solution-service';
import ScenarioService from '../../../services/api/scenario-service';
import ScenarioFilter from '../../scenario/scenario-filter';
import SolutionRankingsTable from './solution-rankings-table'

import baseStyles from "../../../css/const"

import { Paper } from '@material-ui/core';

export default function SolutionRankingsTableContainer(props) {
  const classes = baseStyles();

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [count, setCount] = React.useState(0);
  const [rankings, setRankings] = React.useState([]);

  const [reset, setReset] = React.useState(false);
  const [apply, setApply] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [rankingsLoading, setRankingsLoading] = React.useState(true);

  const values = {
    version: React.useRef(null), // single select
    tags: React.useRef([]), // multiple select
    sources: React.useRef([]), // multiple select
    behaviorType: React.useRef(null), // single select
    obstacleTypes: React.useRef([]), // multiple select
    goalTypes: React.useRef([]), // multiple select
    costFunction: React.useRef(null), // single select
    vehicleModel: React.useRef(null), // single select
    vehicleType: React.useRef(null), // single select
    timeHorizon: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    initialVelocity: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    staticObstacleCount: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    dynamicObstacleCount: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    egoVehicleCount: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    goalRegionCount: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
  };


  const options = {
    version: React.useRef([]),
    sources: React.useRef([]),
    tags: React.useRef([]),
    behaviorType: React.useRef([]),
    obstacleTypes: React.useRef([]),
    goalTypes: React.useRef([]),
    costFunction: React.useRef([]), 
    vehicleModel: React.useRef([]),
    vehicleType: React.useRef([]),
    timeHorizon: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    initialVelocity: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    staticObstacleCount: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    dynamicObstacleCount: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    egoVehicleCount: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
    goalRegionCount: {
      min: React.useRef(0),
      max: React.useRef(0),
    },
  };

  // fill out query parameters
  const setParams = (values, searches=null) => {
    const join = (arr) => (arr.length === 0 ? null : arr.join(","));
    const params = {
      benchmarkId: null,
      page: page + 1,
      page_size: rowsPerPage,
      solution__scenario__version: values.version.current,
      solution__scenario__metadata__behavior_type: values.behaviorType.current,
      solution__scenario__metadata__sources: join(values.sources.current),
      solution__scenario__metadata__tags: join(values.tags.current),
      solution__scenario__metadata__goal_types: join(values.goalTypes.current),
      solution__scenario__metadata__obstacle_types: join(values.obstacleTypes.current),
      solution__scenario__metadata__time_horizon__gte: values.timeHorizon.min.current,
      solution__scenario__metadata__time_horizon__lte: values.timeHorizon.max.current,
      solution__scenario__metadata__max_initial_velocity__gte: values.initialVelocity.min.current,
      solution__scenario__metadata__max_initial_velocity__lte: values.initialVelocity.max.current,
      solution__scenario__metadata__static_obstacle_count__gte: values.staticObstacleCount.min.current,
      solution__scenario__metadata__static_obstacle_count__lte: values.staticObstacleCount.max.current,
      solution__scenario__metadata__dynamic_obstacle_count__gte: values.dynamicObstacleCount.min.current,
      solution__scenario__metadata__dynamic_obstacle_count__lte: values.dynamicObstacleCount.max.current,
      solution__scenario__metadata__ego_vehicle_count__gte: values.egoVehicleCount.min.current,
      solution__scenario__metadata__ego_vehicle_count__lte: values.egoVehicleCount.max.current,
      solution__scenario__metadata__max_goal_region__gte: values.goalRegionCount.min.current,
      solution__scenario__metadata__max_goal_region__lte: values.goalRegionCount.max.current,
      solution__planning_problem_solutions__cost_function: values.costFunction.current,
      solution__planning_problem_solutions__vehicle_model: values.vehicleModel.current,
      solution__planning_problem_solutions__vehicle_type: values.vehicleType.current,
    };

    return params;
  };

  const fetchSolutionMetadata = () => {
    SolutionService.getSolutionMetadata()
    .then((response) => {
      options.costFunction.current = response.data.cost_functions;
      options.vehicleModel.current = response.data.vehicle_models;
      options.vehicleType.current = response.data.vehicle_types;
    })
    .catch((error) => {
      throw error
    })
  }

  React.useEffect(() => {
    const params = props.activityId ? { activities__id: props.activityId } : null;
    ScenarioService.getScenariosSummary(params)
      .then((response) => {
        options.version.current = response.data.versions;
        options.sources.current = response.data.sources;
        options.tags.current = response.data.tags;
        options.behaviorType.current = response.data.behavior_types;
        options.obstacleTypes.current = response.data.obstacle_types;
        options.goalTypes.current = response.data.goal_types;
        options.timeHorizon.max.current = Math.ceil(response.data.max_time_horizon);
        options.initialVelocity.max.current = Math.ceil(response.data.max_initial_velocity);
        options.staticObstacleCount.max.current = Math.ceil(response.data.max_static_obstacle_count);
        options.dynamicObstacleCount.max.current = Math.ceil(response.data.max_dynamic_obstacle_count);
        options.egoVehicleCount.max.current = Math.ceil(response.data.max_ego_vehicle_count);
        options.goalRegionCount.max.current = Math.ceil(response.data.max_goal_region);

        // set max and min values of range sliders
        values.timeHorizon.max.current = Math.ceil(response.data.max_time_horizon);
        values.initialVelocity.max.current = Math.ceil(response.data.max_initial_velocity);
        values.staticObstacleCount.max.current = Math.ceil(response.data.max_static_obstacle_count);
        values.dynamicObstacleCount.max.current = Math.ceil(response.data.max_dynamic_obstacle_count);
        values.egoVehicleCount.max.current = Math.ceil(response.data.max_ego_vehicle_count);
        values.goalRegionCount.max.current = Math.ceil(response.data.max_goal_region);
        
        fetchSolutionMetadata()
        setLoading(false)
        return response;
      })
      .catch((error) => {
        return error.response;
      });
  }, []);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setRankingsLoading(true);
  }

  const handleRowsPerPageChange = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    setRankingsLoading(true);
  }

  React.useEffect(() => {
    let params = setParams(values);

    if (!loading) {
      SolutionService.getBenchmarkRanking(params)
      .then((response) => {
        setCount(response.data.count);
        setRankings(response.data.results);
        setRankingsLoading(false);
      })
      .catch((error) => {
        return error;
      });
    }

  }, [loading, apply, reset, page, rowsPerPage]);

  const handleApplyButtonClick = () => {
    setPage(0);
    setApply(!apply);
    setRankingsLoading(true);
  };

  const handleResetButtonClick = () => {
    for (const key in values) {
      if (Array.isArray(values[key].current)) {
        // array
        values[key].current = [];
      } else if (typeof values[key].current === "string") {
        // string
        values[key].current = null;
      } else if (values[key].current === null) {
        values[key].current = null;
      } else if (typeof values[key].current === "boolean") {
        values[key].current = true;
      } else {
        values[key].min.current = options[key].min.current;
        values[key].max.current = options[key].max.current;
      }
    }
    setPage(0);
    setRowsPerPage(10);
    setReset(!reset);
    setRankingsLoading(true);
  };

  if (loading) {
    return <div style={{ display: "none" }} />;
  } else {
      return (
        <React.Fragment>
          <Paper className={classes.paper}>
            {/* Here it is used for benchmark filtering */}
            <ScenarioFilter
              values={values}
              options={options}
              showPublicFilter={false}
              showSolutionSpecificFilters={true}
              applyClick={handleApplyButtonClick}
              resetClick={handleResetButtonClick}
            />
            <SolutionRankingsTable
              page={page}
              rowsPerPage={rowsPerPage}
              onChangePage={handlePageChange}
              onChangeRowsPerPage={handleRowsPerPageChange}
              count={count}
              rankings={rankings}
              loading={rankingsLoading}
            />
          </Paper>
        </React.Fragment>
      );
    }
}