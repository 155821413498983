import React from 'react';
import ReactMarkdown from 'react-markdown'

import privacyPolicy from '../../assets/markdowns/privacy-policy.md';
import DefaultLayout from '../../components/layouts/default-layout';
import useDocTitle from '../../hooks/use-doc-title';
import useTracker from '../../hooks/use-tracker';


export default function PrivacyPolicy() {

    // set document title
    const [title, setTitle] = useDocTitle("Privacy Policy");

    // setup tracker after setting the document title
    useTracker();

    const [markdown, setMarkdown] = React.useState("");

    React.useEffect(() => {
        fetch(privacyPolicy).then(res => res.text()).then(text => setMarkdown(text));
    }, [])

    return (
        <DefaultLayout>
            <div style={{ width: "70%", margin: "0 auto" }}>
                <ReactMarkdown source={markdown}></ReactMarkdown>
            </div>
        </DefaultLayout>
    )
}