import React from 'react';
import moment from 'moment';
import SlackAppService from '../services/slack-app';
import RuntimeError from '../pages/static/errors/runtime-error';


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true, error: error.message };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    // logErrorToMyService(error, errorInfo);

    console.log(error)
    const data = {
      date: moment().format('MMMM Do YYYY, h:mm:ss a'),
      statusCode: 'Runtime Error',
      stack: `${error.message}\n${errorInfo.componentStack}`
    };
    SlackAppService.log(data);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <RuntimeError />;
    }

    return this.props.children; 
  }
}

export default ErrorBoundary;