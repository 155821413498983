import React from "react";
import moment from 'moment';

import {
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@material-ui/core";
import BlockIcon from "@material-ui/icons/Block";
import DoneIcon from "@material-ui/icons/Done";
import RemoveCircleOutlineIcon from "@material-ui/icons/RemoveCircleOutline";
import {withStyles} from '@material-ui/core/styles';

import { Link } from "react-router-dom";
import DefaultLayout from "../../components/layouts/default-layout";
import LargeLayout from "../../components/layouts/large-layout";
import ActivityService from "../../services/api/activity-service";
import baseStyles from "../../css/const";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import QuickAccess from "../../components/quick-access";
import StorageService from "../../services/local-storage/storage-service";

const LabelButton = withStyles((theme) => ({
  root: {
    color: 'white',
    backgroundColor: theme.palette.success.main,
    '&:hover': {
      backgroundColor: theme.palette.success.main,
    },
  },
}))(Button);

export default function Challenges() {
  const classes = baseStyles();

  // set title
  const [title, setTitle] = useDocTitle("Challenges");

  // setup tracker after setting the document title
  useTracker();

  const [activities, setActivities] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    ActivityService.getActivities()
      .then((response) => {
        const user = StorageService.getUserInformation();

        let tempActivities = [];
        for (const activity of response.data.results) {

          const today = Date.now()
          const start_date = new Date(activity.start_date);
          const end_date = new Date(activity.end_date);
          const status = (today < start_date) ? 'Incoming' : (today <= end_date) ? 'Active' : 'Completed';

          const tempActivity = {
            id: activity.id,
            title: activity.title,
            summary: activity.summary,
            enrolled: user === null ? -1 : user.activities.includes(activity.id) ? 1 : 0,
            hidden: activity.hidden,
            participants: activity.user_count,
            status: status
          };

          tempActivities.push(tempActivity);
        }
        
        return tempActivities;
      })
      .then((res) => {
        setActivities(res);
        setLoading(false);
      });
  }, []);

  return (
    <LargeLayout>
      <Grid container direction="column" alignItems="stretch" justify="center" spacing={3}>
        <Grid item>
          <Typography className={classes.title} align="left" gutterBottom>
            Challenges
          </Typography>
          <Typography align="left" gutterBottom>
            Compete against motion planners of other users in predefined benchmark sets. Host your own challenge as described in this <a href={"/challenges/tutorial"}>tutorial</a>.
          </Typography>
        </Grid>

        <Grid item container direction="row" justify="center" spacing={2}>
          <Grid item xs={12} lg={10} xl={10}>
            <Paper className={classes.paper}>
              <TableContainer container={Paper}>
                <Table>
                  <TableHead>
                    <TableCell>Challenges</TableCell>
                    <TableCell>Participants</TableCell>
                    <TableCell>Enrollment</TableCell>
                    <TableCell>Submission type</TableCell>
                    <TableCell>Status</TableCell>
                  </TableHead>

                  <TableBody>
                    {loading && (
                    <TableRow style={{height: '60px'}}>
                      <TableCell colSpan={5}>
                        <Grid container justify='center'>
                          <Grid item>
                          <CircularProgress />
                          </Grid>
                        </Grid>
                      </TableCell>
                    </TableRow>
                    )}
                    {!loading && activities.map((activity) => (
                      <TableRow
                        component={Link}
                        to={`/challenges/id/${activity.id}`}
                        key={activity.id}
                        style={{ textDecoration: "none" }}
                      >
                        <TableCell>
                          <Grid container spacing={3}>
                            <Grid item xs sm container>
                              <Grid item xs container direction="column" justify="flex-end">
                                <Grid item xs>
                                  <Typography variant="body2" gutterBottom>
                                    {activity.title}
                                  </Typography>
                                  <Typography variant="body2" color="textSecondary" gutterBottom>
                                    {activity.summary}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </TableCell>

                        <TableCell>{activity.participants}</TableCell>
                        <TableCell>
                          {activity.enrolled === -1 ? (
                            <Tooltip title={'You have to be logged-in in order to see your registration status'}>
                              <RemoveCircleOutlineIcon htmlColor="" gray />
                            </Tooltip>
                          ) : activity.enrolled === 1 ? (
                            <DoneIcon htmlColor="green" />
                          ) : (
                            <BlockIcon htmlColor="red" />
                          )}
                        </TableCell>
                        <TableCell>
                          <Grid container spacing={2}>
                            <Grid item>
                              <LabelButton variant="contained" size="small" disableFocusRipple>
                                {activity.hidden === true ? ("Docker Image URL") : ("XML files")}
                              </LabelButton>
                            </Grid>
                          </Grid>
                        </TableCell>
                        <TableCell>{activity.status}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Paper>
          </Grid>

          <Grid item xs={12} lg={2} xl={2}>
            <QuickAccess />
          </Grid>
        </Grid>
      </Grid>
    </LargeLayout>
  );
}
