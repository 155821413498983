import React from "react";
import { TextField, Button, Paper, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import DefaultLayout from "../../components/layouts/default-layout";
import { useEffect } from "react";
import { useHistory } from "react-router";
import useDocTitle from "../../hooks/use-doc-title";
import UserService from "../../services/api/user-service";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: theme.palette.error.main,
  },
}));

export default function ResetPassword(props) {
  // set document title
  const [title, setTitle] = useDocTitle("Reset Password");

  const classes = useStyles();
  const history = useHistory();

  const token = props.match.params.hasOwnProperty("token") ? props.match.params.token : null;

  const [success, setSuccess] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState(null);
  const [newPasswordStatus, setNewPasswordStatus] = React.useState(null);
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState(null);
  const [newPasswordConfirmStatus, setNewPasswordConfirmStatus] = React.useState(null);

  const handleReset = (evt) => {
    evt.preventDefault();

    UserService.resetPassword(token, newPassword, newPasswordConfirm)
      .then(() => {
        setNewPasswordStatus(null);
        setSuccess(true);
        setTimeout(() => history.push("/"), 4000);
      })
      .catch((error) => {
        setNewPasswordStatus(error.data.password[0]);
        setSuccess(false);
      });
  };

  useEffect(() => {
    const msg = "Password confirmation do not match the password!";
    const status = newPasswordConfirm !== newPassword && newPasswordConfirm !== null ? msg : null;
    setNewPasswordConfirmStatus(status);
  }, [newPassword, newPasswordConfirm]);

  return (
    <DefaultLayout>
      {!success ? (
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleReset}>
              <TextField
                error={newPasswordStatus}
                helperText={newPasswordStatus ? newPasswordStatus : null}
                required
                fullWidth
                variant="outlined"
                margin="normal"
                label="New Password"
                type="password"
                onChange={(evt) => setNewPassword(evt.target.value)}
                value={newPassword}
                autoFocus
              />

              <TextField
                error={newPasswordConfirmStatus}
                helperText={newPasswordConfirmStatus ? newPasswordConfirmStatus : null}
                required
                fullWidth
                variant="outlined"
                margin="normal"
                label="New Password Confirm"
                type="password"
                onChange={(evt) => setNewPasswordConfirm(evt.target.value)}
                value={newPasswordConfirm}
              />

              <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: "10px" }}>
                Reset Password
              </Button>
            </form>
          </div>
        </Container>
      ) : (
        <Paper className={classes.successPaper}>
          <Typography variant="h4">Success</Typography>
          <Typography variant="body2">Your password has been successfully updated.</Typography>
          <Typography variant="body2">You can login to your account with your new password.</Typography>
        </Paper>
      )}
    </DefaultLayout>
  );
}
