import React from 'react'
import ReactMarkdown from 'react-markdown'

import competitionDetails from "../../assets/markdowns/competition-details.md";
import {Paper, Typography} from "@material-ui/core";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import QuickAccessCompetition from "../../components/quick-access-competition";
import baseStyle from "../../css/const";

export default function CompetitionDetails() {
  const [title, setTitle] = useDocTitle("Commonroad Competition - Details");

  useTracker();
  const classes = baseStyle();
  const [markdown, setMarkdown] = React.useState("");

  React.useEffect(() => {
    fetch(competitionDetails)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

const _mapProps = (props) => ({
  ...props,
  escapeHtml: false
});

const Markdown = (props) => <ReactMarkdown {..._mapProps(props)} />;
  // d_{safe}() = \dfrac{v^2_{o}}{-2\left|a_o^{min}\right|} - \dfrac{v_e^2}{-2\left |a_e^{min}\right|} + v_e t_d
  return (
    <DefaultLayout>
      <Grid container direction={'row'} spacing={2}>
      <Grid item xs={12} md={10}>
        <Paper className={classes.paper}>
        <Markdown source={markdown} />
        </Paper>
        </Grid>
        <Grid item xs={12} md={2}>
          <QuickAccessCompetition/>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
}
