import React from "react";
import { Table, TableContainer, TableRow, TableBody, TableCell, Typography, Grid } from "@material-ui/core";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import SolutionService from "../../services/api/solution-service";

export default function SubmissionDetails(props) {
  const solutionId = props.match.params.id;

  // set document title
  const [docTitle, setDocTitle] = useDocTitle("Details of " + solutionId);

  // setup tracker after setting the document title
  useTracker();

  const [solution, setSolution] = React.useState({});
  const [user, setUser] = React.useState({});

  const [submissionDetails, setSubmissionDetails] = React.useState({});
  const [benchmarkDetails, setBenchmarkDetails] = React.useState({});

  React.useEffect(() => {
    SolutionService.getSolution(solutionId).then((response) => {
      const solution = response.data;
      const user = response.data.user;

      const submissionDetails = {
        username: {
          placeholder: "Username",
          value: user.username,
        },

        country: {
          placeholder: "Country",
          value: user.profile.country,
        },

        organization: {
          placeholder: "Organization",
          value: user.profile.institution,
        },

        "submission-date": {
          placeholder: "Submission Date",
          value: solution.date,
        },

        method: {
          placeholder: "Method",
          value: solution.method,
        },

        "code-url": {
          placeholder: "Code URL",
          value: solution.codeUrl,
        },
      };

      const benchmarkDetails = {
        "benchmark-id": {
          placeholder: "Benchmark ID",
          value: solution.benchmark_id,
        },

        status: {
          placeholder: "Status",
          value: solution.evaluation_status,
        },

        "total-cost": {
          placeholder: "Total Cost",
          value: solution.cost,
        },

        cpu: {
          placeholder: "CPU",
          value: solution.cpu,
        },

        "processing-time": {
          placeholder: "Computation time [s]",
          value: solution.computation_time,
        },

        algorithm: {
          placeholder: "Algorithm",
          value: solution.algorithm,
        },
      };

      setSubmissionDetails(submissionDetails);
      setBenchmarkDetails(benchmarkDetails);
    });
  }, [solutionId]);

  /* const solutionDetails = {
        "total-cost": {
            placeholer: "Total Cost",
            value: 10000
        },

        "A": {
            placeholder: "A",
            value: 1
        },

        "SA": {
            placeholder: "SA",
            value: 1
        },

        "SR": {
            placeholder: "SR",
            value: 1
        },

        "LC": {
            placeholder: "LC",
            value: 1
        },

        "V": {
            placeholder: "V",
            value: 1
        },

        "O": {
            placeholder: "O",
            value: 1
        }
    }*/

  return (
    <DefaultLayout>
      <Grid container direction="row" alignItems="stretch" justify="center" spacing={3}>
        <Grid item sm={12} md={6}>
          <Typography variant="h4"> Submission Details </Typography>
          <TableContainer>
            <Table>
              <TableBody>
                {Object.keys(submissionDetails).map((key, index) => {
                  return (
                    <TableRow key={key}>
                      <TableCell align="left">{submissionDetails[key]["placeholder"]}</TableCell>
                      <TableCell align="right">{submissionDetails[key]["value"]}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>

        <Grid item sm={12} md={6}>
          <Typography variant="h4"> Benchmark Details </Typography>
          <TableContainer>
            <Table>
              <TableBody>
                {Object.keys(benchmarkDetails).map((key, index) => {
                  return (
                    <TableRow key={key}>
                      <TableCell align="left">{benchmarkDetails[key]["placeholder"]}</TableCell>
                      <TableCell align="right">{benchmarkDetails[key]["value"]}</TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      </Grid>

      {/*<div style={{ display: "flex", justifyContent: "space-evenly", marginTop: "30px" }}>


                <div>
                    <Typography variant="h4"> Solution Details </Typography>
                    <TableContainer>
                        <Table style={{ maxWidth: "500px" }}>
                            <TableBody>
                                {Object.keys(solutionDetails).map((key, index) => {
                                    return (
                                        <TableRow key={key}>
                                            <TableCell align="left">{solutionDetails[key]["placeholder"]}</TableCell>
                                            <TableCell align="right">{solutionDetails[key]["value"]}</TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>*/}
    </DefaultLayout>
  );
}
