import {Box, Button, Grid, IconButton, Paper, Typography} from "@material-ui/core";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import React from "react";
import { Link } from "react-router-dom";
import DefaultLayout from "../../../components/layouts/default-layout";
import BenchmarkRankingsTableContainer from "../../../components/rankings/benchmark-ranking/benchmark-rankings-table-container";
import BenchmarkIdExplainer from "../../../components/benchmark/benchmark-id-explainer";
import baseStyles from "../../../css/const";
import SolutionService from "../../../services/api/solution-service";
import Collapse from "@material-ui/core/Collapse";
import useDocTitle from '../../../hooks/use-doc-title';
import useTracker from '../../../hooks/use-tracker';
import { useHistory } from 'react-router-dom';

export default function BenchmarkRanking(props) {
  const classes = baseStyles();

  const history = useHistory();

  const benchmarkId = props.match.params.benchmarkId;
  const [title, setTitle] = useDocTitle(`Rankings of ${benchmarkId}`);
  useTracker();

  const [nextBenchmarkId, setNextBenchmarkId] = React.useState(benchmarkId);
  const [prevBenchmarkId, setPrevBenchmarkId] = React.useState(benchmarkId);

  React.useEffect(() => {
    SolutionService.getBenchmarkNames(null, true).then((response) => {
      const idx = response.data.benchmarks.findIndex(bid => bid === benchmarkId);
      const benchmarkListLength = response.data.benchmarks.length;
      const nextIdx = (idx + 1) % benchmarkListLength;
      const prevIdx = (idx === 0) ? benchmarkListLength - 1 : idx - 1;

      setNextBenchmarkId(response.data.benchmarks[nextIdx]);
      setPrevBenchmarkId(response.data.benchmarks[prevIdx]);
    }).catch((error) => {
      history.replace(history.location.pathname, {
        errorCode: 'error'
      })

    })
  }, [benchmarkId]);

  return (
    <DefaultLayout>
      <Grid container direction="column" alignItems="stretch" justify="center" spacing={3}>
        <Grid item xs ={12}>

          <Grid item container xs={12} justify='space-between'>
            <Grid>
              <Button
                component={Link}
                to={`/solutions/ranking/${prevBenchmarkId}`}
                style={{textDecoration: 'none', outline: 'none'}}
                startIcon={<ArrowBackIcon />}
              >
                Previous
              </Button>
            </Grid>

            <Grid>
              <Button
                component={Link}
                to={`/solutions/ranking/${nextBenchmarkId}`}
                style={{textDecoration: 'none', outline: 'none'}}
                startIcon={<ArrowForwardIcon />}
              >
                Next
              </Button>
            </Grid>
          </Grid>

        </Grid>

        <Grid item xs={12}>
          <BenchmarkRankingsTableContainer benchmarkId={benchmarkId}/>
        </Grid>
      </Grid>
    </DefaultLayout>
  );
}
