import React from "react";
import {
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  Typography,
  Divider,
  Snackbar,
} from "@material-ui/core";
import Alert from '@material-ui/lab/Alert';

import countries from "../../json/countries.json";
import DefaultLayout from "../../components/layouts/default-layout";
import { useHistory } from "react-router";
import useDocTitle from "../../hooks/use-doc-title";
import StorageService from "../../services/local-storage/storage-service";

import { makeStyles } from "@material-ui/core/styles";
import UserService from "../../services/api/user-service";
import AuthenticationService from "../../services/api/authentication-service";

const useStyles = makeStyles(theme => ({
  textField: {
    '& input': {
      paddingBottom: '10px',
      paddingTop: '30px'
    }
  },
}));

export default function ProfilePage() {

  const classes = useStyles();

  // set document title
  const [title, setTitle] = useDocTitle("My Profile");

  const history = useHistory();

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [severity, setSeverity] = React.useState(null);

  const [user, setUser] = React.useState(StorageService.getUserInformation());
  const [username, setUsername] = React.useState(user.username);
  const [firstName, setFirstName] = React.useState(user.first_name);
  const [lastName, setLastName] = React.useState(user.last_name);
  const [institution, setInstitution] = React.useState(user.profile.institution);
  const [country, setCountry] = React.useState(user.profile.country);

  const [usernameStatus, setUsernameStatus] = React.useState(null);
  const [firstNameStatus, setFirstNameStatus] = React.useState(null);
  const [lastNameStatus, setLastNameStatus] = React.useState(null);
  const [institutionStatus, setInstitutionStatus] = React.useState(null);
  const [countryStatus, setCountryStatus] = React.useState(null);

  const [oldPassword, setOldPassword] = React.useState(null);
  const [newPassword, setNewPassword] = React.useState(null);
  const [newPasswordConfirm, setNewPasswordConfirm] = React.useState(null);

  const [oldPasswordStatus, setOldPasswordStatus] = React.useState(null);
  const [newPasswordStatus, setNewPasswordStatus] = React.useState(null);
  const [newPasswordConfirmStatus, setNewPasswordConfirmStatus] = React.useState(null);


  React.useEffect(() => {
    const msg = "Password confirmation do not match the password!";
    const status = newPasswordConfirm !== newPassword && newPasswordConfirm !== null ? msg : null;
    setNewPasswordConfirmStatus(status);
  }, [newPassword, newPasswordConfirm]);

  const handleUpdate = (event) => {
    event.preventDefault();

    UserService.update(
      user.id,
      username,
      firstName,
      lastName,
      country,
      institution
    ).then((response) => {
      
      StorageService.storeUserInformation(response.data);
      setUser(response.data);
      setSeverity('success');
      setSnackbarOpen(true);

      history.push(0);
    }).catch((error) => {
      setSeverity('error');
      setSnackbarOpen(true);

      if ("username" in error.data) setUsernameStatus(error.data.username[0]);

      if ("first_name" in error.data) setFirstNameStatus(error.data.first_name[0]);

      if ("last_name" in error.data) setLastNameStatus(error.data.last_name[0]);

      if ("profile" in error.data && "country" in error.data.profile)
        setCountryStatus(error.data.profile.country[0]);

      if ("profile" in error.data && "institution" in error.data.profile)
        setInstitutionStatus(error.data.profile.institution[0]);
    });
  };

  const handlePasswordChange = (event) => {
    event.preventDefault();
    setOldPasswordStatus(null);
    setNewPasswordStatus(null);
    setNewPasswordConfirmStatus(null);

    UserService.changePassword(
      user.id,
      oldPassword,
      newPassword,
      newPasswordConfirm
    ).then(() => {
      AuthenticationService.logout(); // forse users to sgn in again
      history.push('/'); // redirect to index page
    }).catch((error) => {
      
      setSeverity('error');
      setSnackbarOpen(true);

      if ("old_password" in error.data) setOldPasswordStatus(error.data.old_password[0]);

      if ("password" in error.data) setNewPasswordStatus(error.data.password[0]);

      if ("password_confirm" in error.data)
        setNewPasswordConfirmStatus(error.data.password_confirm[0]);
    });
  };

  return (
    <DefaultLayout>
      <Snackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{
          'vertical': 'bottom',
          'horizontal': 'left'
        }}
      >
        <Alert severity={severity} onClose={() => setSnackbarOpen(false)}>
          {
            (severity) === 'success'
            ? 'Changes saved'
            : 'Some error occurred, re-check your respective inputs'
          }
        </Alert>
      </Snackbar>
        <form noValidate autoComplete="off" onSubmit={handleUpdate}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">My Profile</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={username}
                error={usernameStatus}
                helperText={usernameStatus ? usernameStatus : null}
                onChange={(event) => setUsername(event.target.value)}
                className={classes.textField}
                id="username"
                name="username"
                label="Username"
                variant="outlined"
                fullWidth
              ></TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.textField}
                value={firstName}
                error={firstNameStatus}
                helperText={firstNameStatus ? firstNameStatus : null}
                onChange={(event) => setFirstName(event.target.value)}
                id="firstName"
                name="firstName"
                label="First Name"
                variant="outlined"
                fullWidth
              ></TextField>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                className={classes.textField}
                value={lastName}
                error={lastNameStatus}
                helperText={lastNameStatus ? lastNameStatus : null}
                onChange={(event) => setLastName(event.target.value)}
                id="lastName"
                name="lastName"
                label="Last Name"
                variant="outlined"
                fullWidth
              ></TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                value={institution}
                error={institutionStatus}
                helperText={institutionStatus ? institutionStatus : null}
                onChange={(event) => setInstitution(event.target.value)}
                id="institution"
                name="institution"
                label="Institution"
                variant="outlined"
                fullWidth
              ></TextField>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth variant="outlined">
                <InputLabel id="select-country-label">Country</InputLabel>
                <Select
                  SelectDisplayProps={{
                    style:{
                      paddingBottom: '10px',
                      paddingTop: '30px'  
                    }
                  }}
                  value={country}
                  error={countryStatus}
                  helperText={countryStatus ? countryStatus : null}
                  onChange={(event) => setCountry(event.target.value)}
                  className={classes.textField}
                >
                  {countries.map((x) => (
                    <MenuItem value={x.code}> {x.name} </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid xs={12} md={3} style={{ padding: "12px" }}>
              <Button type="submit" fullWidth variant="contained" color="primary">
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </form>

        <Divider style={{ marginTop: "30px", marginBottom: "30px" }}></Divider>

        <form noValidate autoComplete="off" onSubmit={handlePasswordChange}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">Password Change</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                value={oldPassword}
                error={oldPasswordStatus}
                helperText={oldPasswordStatus ? oldPasswordStatus : null}
                onChange={(event) => setOldPassword(event.target.value)}
                id="oldPassword"
                name="oldPassword"
                label="Old Password"
                variant="outlined"
                type="password"
                fullWidth
              ></TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                value={newPassword}
                error={newPasswordStatus}
                helperText={newPasswordStatus ? newPasswordStatus : null}
                onChange={(event) => setNewPassword(event.target.value)}
                id="newPassword"
                name="newPassword"
                label="New Password"
                variant="outlined"
                type="password"
                fullWidth
              ></TextField>
            </Grid>

            <Grid item xs={12}>
              <TextField
                className={classes.textField}
                value={newPasswordConfirm}
                error={newPasswordConfirmStatus}
                helperText={newPasswordConfirmStatus ? newPasswordConfirmStatus : null}
                onChange={(event) => setNewPasswordConfirm(event.target.value)}
                id="newPasswordConfirm"
                name="newPasswordConfirm"
                label="Confirm New Password"
                variant="outlined"
                type="password"
                fullWidth
              ></TextField>
            </Grid>

            <Grid item xs={12} md={3}>
              <Button type="submit" variant="contained" color="primary" fullWidth>
                Change Password
              </Button>
            </Grid>
          </Grid>
        </form>
    </DefaultLayout>
  );
}
