import React from "react";
import { Grid, Typography } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { useHistory } from "react-router-dom";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import UserService from "../../services/api/user-service";
import useTracker from "../../hooks/use-tracker";

export default function EmailVerification(props) {
  // set document title
  const [title, setTitle] = useDocTitle("Email Verification");
  useTracker();

  // const classes = useStyles();
  const history = useHistory();
  const token = props.match.params.token;

  const [verified, setVerified] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  React.useEffect(() => {
    UserService.activate(token)
      .then(() => {
        setVerified(true);
        setTimeout(() => history.push("/"), 4000);
      })
      .catch((error) => {
        setVerified(false);
        setErrorMessage(error.data.token[0]);
      });
  }, []);

  return (
    <DefaultLayout>
      <Grid container justify="center" alignItems="center" align="center">
        <Grid item xs={12}>
          {verified ? (
            <DoneIcon htmlColor="green" style={{ fontSize: "400%" }} />
          ) : (
            <ErrorOutlineIcon htmlColor="red" style={{ fontSize: "400%" }} />
          )}
        </Grid>
        <Grid item xs={12}>
          {verified ? (
            <Typography variant='h5'>Your email has been verified. You will be redirected to the home page in a second.</Typography>
          ) : (
            <React.Fragment>
              <Typography variant='h4'>There has been an error while activating your account.</Typography>
              <Typography variant='h5'>{errorMessage}</Typography>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </DefaultLayout>
  );
}
