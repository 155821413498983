import React from "react";
import SolutionService from "../../../services/api/solution-service";
import BenchmarkRankingsTable from "./benchmark-rankings-table";

export default function BenchmarkRankingsTableContainer(props) {

  const bestSolution = React.useRef(null);

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [count, setCount] = React.useState(0);
  const [rankings, setRankings] = React.useState([]);
  const [loading, setLoading] = React.useState(true);

  const params = {
    benchmarkId: props.benchmarkId,
    page: page + 1,
    page_size: rowsPerPage,
  }

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setLoading(true);
  };

  const handleRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(event.target.value);
    setLoading(true);
  };

  React.useEffect(() => {
    SolutionService.getBenchmarkRanking(params).then((response) => {
      
      if (page === 0) {
        bestSolution.current = response.data.results[0];
      }
      
      setRankings(response.data.results);
      setCount(response.data.count);
      setLoading(false);
    });
  }, [props.benchmarkId, page, rowsPerPage]);

  return (
    <React.Fragment>
      <BenchmarkRankingsTable
        benchmarkId={props.benchmarkId}
        page={page}
        rowsPerPage={rowsPerPage}
        count={count}
        solutions={rankings}
        bestSolution={bestSolution}
        onChangePage={handlePageChange}
        onChangeRowsPerPage={handleRowsPerPage}
        loading={loading}
      />
    </React.Fragment>
  )
}