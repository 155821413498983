import React from 'react';
import {Typography, List, ListItem, Paper} from '@material-ui/core';
import DefaultLayout from '../../components/layouts/default-layout';
import useDocTitle from '../../hooks/use-doc-title';
import useTracker from '../../hooks/use-tracker';
import baseStyle from "../../css/const";
import Grid from "@material-ui/core/Grid";

export default function Internship() {

  // set document title
  const [title, setTitle] = useDocTitle("Internship Opportunities");

  // setup tracker after setting the document title
  useTracker();
  const classes = baseStyle();

  const listItems = [
    "Integration of operation systems such as ROS (Python/C++)",
    "Connecting CommonRoad to Autoware (C++)",
    "Reading and writing of CommonRoad scenarios with MATLAB",
    "Development of CommonRoad-RL (Python)",
    "Development of Traffic Rule Monitors (C++/Python)",
    "Development of CommonRoad Scenario Designer (Python)",
    "Learning the robustness degree of traffic rule predicates (Python)",
    "Automatic generation of critical traffic scenarios (Python)",
    "Development of our CommonRoad website for the evaluation of motion planning algorithms (Django, Python, Javascript)",
    "Improving our teaching material for motion planning (Django, Python)",
    "Execution and preparation of hardware-in-the-loop tests or real vehicle tests (Python)",
  ]
  return (
    <DefaultLayout>
      <Typography className={classes.title}>
        Internship Opportunities
      </Typography>
      <Grid item>
        <Paper className={classes.paper}>
          <Typography>
            We are constantly seeking for motivated interns to help us further develop the CommonRoad platform.
            Please note that <b>international interns</b> are only accepted through DAAD or similar programs.
          </Typography>
          <br/>
          <Typography> Current areas we are seeking expertise in are, but are not limited to: </Typography>
          <List>
            {listItems.map((item, idx) => {
              return (
                <ListItem key={idx}>
                  <Typography> - {item}</Typography>
                </ListItem>
              )
            })}
          </List>
        <Typography className={classes.subtitle}>
          Application
        </Typography>
          <Typography>
            In case you are interested in the offered internship position, please apply through our <a href="https://wiki.tum.de/display/cpsforms/Internship+Application">application portal</a>.
          </Typography>
        </Paper>
      </Grid>

    </DefaultLayout>
  )
}