import {Paper, Typography} from '@material-ui/core';
import React from 'react';
import DefaultLayout from '../../../components/layouts/default-layout';
import SolutionRankingsTableContainer from '../../../components/rankings/global-ranking/solution-rankings-table-container';
import useDocTitle from '../../../hooks/use-doc-title';
import useTracker from '../../../hooks/use-tracker';

import baseStyles from '../../../css/const';
import Grid from "@material-ui/core/Grid";

export default function SolutionsGlobalRanking() {
  const classes = baseStyles();

  const [title, setTitle] = useDocTitle('Benchmark Leaderboards');
  useTracker();

  return (
    <DefaultLayout>
      <Grid container direction={'column'} spacing={1}>
        <Grid item>
          <Typography className={classes.title}>
            Benchmark Leaderboards
          </Typography>
        </Grid>
        <Paper  className={classes.paper}>
          <Grid item>
            <Typography>
              This table shows the best users for each benchmark. Click on the benchmark IDs to see the ranking for a specific benchmark.
              More details about the benchmark configuration can be found in our&nbsp;
              <a target="_blank" rel="noopener noreferrer" href="http://mediatum.ub.tum.de/doc/1379638/776321.pdf">
                paper
              </a>{" "}
              or under <a href={"/model-cost-functions"}>vehicle models and cost functions</a>.
            </Typography>
          </Grid>
        </Paper>
        <SolutionRankingsTableContainer />
      </Grid>
    </DefaultLayout>
  )
  
}