export default class StorageService {
  static _accessTokenName = 'ACCESS';
  static _refreshTokenName = 'REFRESH';
  static _tokenKey = 'AUTH_TOKEN_';
  static _tokenExpKey = 'AUTH_TOKEN_EXP_';
  static _tokenJTIKey = 'AUTH_TOKEN_JTI_';
  static _tokenUUIDKey = 'AUTH_TOKEN_UUID_';
  static _header_prefix = 'Bearer ';

  static decodeToken(token) {
      return JSON.parse(atob(token.split('.')[1]));
  }

  static storeToken(token, name) {
      const decodedToken = this.decodeToken(token);
      localStorage.setItem(this._tokenKey + name, token);
      localStorage.setItem(this._tokenExpKey + name, decodedToken.exp);
      localStorage.setItem(this._tokenUUIDKey + name, decodedToken.user_id);
  }

  static storeUserInformation(information) {
      localStorage.setItem('AUTH_USER', JSON.stringify(information));
  }

  static getAccessToken() {
      return localStorage.getItem(this._tokenKey + this._accessTokenName);
  }

  static getAccessTokenExp() {
      return localStorage.getItem(this._tokenExpKey + this._accessTokenName);
  }

  static getRefreshToken() {
      return localStorage.getItem(this._tokenKey + this._refreshTokenName);
  }

  static getRefreshTokenExp() {
      return localStorage.getItem(this._tokenExpKey + this._refreshTokenName);
  }

  static getUserId() {
      return localStorage.getItem(this._tokenUUIDKey + this._refreshTokenName);
  }

  static getUserInformation() {
      return JSON.parse(localStorage.getItem('AUTH_USER'));
  }

  static isTokenExpired(name, marginInSec=30) {
      const token = localStorage.getItem(this._tokenKey + name);
      if (token == null) {
          return true;
      }
      const margin = marginInSec * 1000;
      const tokenExp = localStorage.getItem(this._tokenExpKey + name);
      const tokenExpired = Date.now() + margin >= tokenExp * 1000;
      return tokenExpired;
  }

  static clearToken(name) {
      localStorage.removeItem(this._tokenKey + name);
      localStorage.removeItem(this._tokenExpKey + name);
      localStorage.removeItem(this._tokenJTIKey + name);
      localStorage.removeItem(this._tokenUUIDKey + name);
  }

  static clearAll() {
      localStorage.clear();
  }
}