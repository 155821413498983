import { Button, InputAdornment, TextField } from "@material-ui/core";
import FilterListIcon from "@material-ui/icons/FilterList";
import { makeStyles } from "@material-ui/core/styles";
import React from "react";

export default function ScenarioTableSearchField(props) {
  const [value, setValue] = React.useState('');

  React.useEffect(() => {
    props.reference.current = value;

    const debounce = setTimeout(() => {
      if (value !== null) {
        props.setSearch(true);
      }
    }, 1000);

    return () => clearTimeout(debounce);
  }, [value]);

  return (
    <React.Fragment>
      <TextField
        id="standard"
        value={value}
        onChange={(event) => setValue(event.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <FilterListIcon />
            </InputAdornment>
          ),
        }}
      />
    </React.Fragment>
  );
}
