import {
  Backdrop,
  Box,
  CircularProgress,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import React from "react";
import ActivityService from "../../../services/api/activity-service";

import { Link } from "react-router-dom";
import StorageService from "../../../services/local-storage/storage-service";
import SolutionService from "../../../services/api/solution-service";

export default function ChallengeLeaderboardRow(props) {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [benchmarkRows, setBenchmarkRows] = React.useState([]);
  const hidden = props.hidden;
  const isDocker = props.isDocker;

  function getUserSubmissions(props, hidden, page) {
    const query = {
      page: page + 1,
      page_size: rowsPerPage,
    };
    if (isDocker) {
      SolutionService.getDockerSubmissionSolutions(props.id, query).then((response) => {
        setBenchmarkRows(response.data.results);
        setLoading(false);
      });
    } else {
      ActivityService.getActivityUserBenchmarks(props.activityId, props.id, query).then((response) => {
        setBenchmarkRows(response.data.results);
        setLoading(false);
      });
    }
  }

  // prevent useEffect to be called for every row in the table as component mounts
  const isMounted = React.useRef(false);

  // submission list states
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);

  React.useEffect(() => {
    // only load user submissions if not hidden
    if (!hidden) {
      if (open) {
        getUserSubmissions(props, hidden, page);
      }
    }
  }, [open, page, hidden, rowsPerPage]);

  const handleIconClick = (event) => {
    // button is not rendered for hidden challenges
    event.preventDefault();

    setOpen(!open); // open or close the collapsible component
    getUserSubmissions(props, hidden, page);
  };
  return (
    <React.Fragment>
      <TableRow key={props.id}>
        <TableCell>
          {!hidden ? (
            <IconButton key={props.id} aria-label="expand row" size="small" onClick={handleIconClick}>
              {open ? <KeyboardArrowUpIcon id={props.id} /> : <KeyboardArrowDownIcon id={props.id} />}
            </IconButton>
          ) : null}
        </TableCell>

        <TableCell>{props.rank}</TableCell>

        {isDocker && !hidden ? <TableCell>{props.public.toString()}</TableCell> : null}
        <TableCell>{props.username}</TableCell>
        <TableCell>{props.institution}</TableCell>
        <TableCell>{props.country}</TableCell>
        <TableCell>{props.best_solution_count}</TableCell>
        <TableCell>{props.solved_scenario_count}</TableCell>
      </TableRow>

      {!hidden ? (
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: "#f5f5f7" }} colSpan={8}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom>
                  Submissions of {props.username}
                </Typography>
              </Box>

              <TableContainer component="div">
                <TablePagination
                  component="div"
                  page={page}
                  count={props.solved_scenario_count}
                  rowsPerPage={rowsPerPage}
                  rowsPerPageOptions={[10, 25, 50]}
                  onChangePage={(event, page) => {setPage(page); setLoading(true);}}
                  onChangeRowsPerPage={(event) => {
                    setPage(0);
                    setRowsPerPage(event.target.value);
                    setLoading(true);
                  }}
                />
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Benchmark&nbsp;Name</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell style={{ textAlign: "right" }}>Costs</TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody
                    style={{
                      position: "relative",
                    }}
                  >
                    {
                      <Backdrop style={{ position: "absolute", zIndex: 1, opacity: 0.3 }} open={loading}>
                        <CircularProgress />
                      </Backdrop>
                    }
                    {benchmarkRows.map((row) => (
                      <TableRow key={row.id}>
                        <TableCell component={Link} to={`/solutions/ranking/${row.benchmark_id}/`}>
                          {row.benchmark_id}
                        </TableCell>
                        <TableCell>{row.created.slice(0, 19).replace("T", " ")}</TableCell>
                        <TableCell style={{ textAlign: "right" }}>{row.cost.toFixed(2)}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Collapse>
          </TableCell>
        </TableRow>
      ) : null}
    </React.Fragment>
  );
}
