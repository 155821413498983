import React from "react";
import DefaultLayout from "../../components/layouts/default-layout";
import ScenariosContainer from './scenarios-container';
import useDocTitle from '../../hooks/use-doc-title';
import useTracker from '../../hooks/use-tracker';
import StorageService from "../../services/local-storage/storage-service";
import Typography from "@material-ui/core/Typography";
import baseStyles from "../../css/const";
import Grid from "@material-ui/core/Grid";
import {Paper} from "@material-ui/core";

export default function Scenario() {

  const [selected, setSelected] = React.useState();
  const classes = baseStyles();
  const [title, setTitle] = useDocTitle('Scenarios');
  const user = StorageService.getUserInformation();

  useTracker();
  return (
      <DefaultLayout>
          <Grid container direction={'column'} spacing={1}>
              <Grid item>
                  <Typography className={classes.title}>
                      Scenario visualization and selection
                  </Typography>
              </Grid>
              <Grid item>
                 <Paper className={classes.paper}>
                      <Typography className={classes.typoContent} variant="body1">
                        The traffic scenarios range from simple scenarios with a few obstacles to complex
                        scenarios in channels with many users. The scenarios are either obtained from real
                        traffic data (e.g., MarineCadastre dataset), or are hand-crafted. The data for each
                        scenario is stored in separate XML files that can be downloaded from below or from
                        the <a href={'https://gitlab.lrz.de/tum-cps/commonocean-scenarios'}>scenario repository</a>.
                        We provide a scenario selection tool, which eases identifying relevant scenarios for a
                        specific use case.
                          {/* For details on the format of the scenario files, please download
                          the <a href={'https://gitlab.lrz.de/tum-cps/commonroad-scenarios/-/blob/master/documentation/XML_commonRoad_2020a.pdf'}>XML format documentation</a>.*/}
                      </Typography>
                  </Paper>
              </Grid>
              <Grid item>
                  <ScenariosContainer selectable={true} downloadable={true} selected={selected} setSelected={setSelected}
                                      showPublicFilter={user !== null ? user.is_staff : false}/>
              </Grid>
          </Grid>
    </DefaultLayout>
  )
}
