import React from "react";
import { Table, TableHead, TableRow, TableCell, TableBody } from "@material-ui/core";

import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import baseStyles from '../../css/const';

export default function BenchmarkTable(props) {
  const classes = baseStyles();

  const benchmarks = props.benchmarks || [];

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Benchmark&nbsp;Name</TableCell>
              <TableCell>Best Cost</TableCell>
              <TableCell>Leader</TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {benchmarks.map((benchmark) => (
              <TableRow key={benchmark.benchmarkId}>
                <TableCell>
                  <Link to={"/solutions/ranking/" + benchmark.benchmarkId}>{benchmark.benchmarkId}</Link>
                </TableCell>
                <TableCell>{benchmark.cost.toFixed(2)}</TableCell>
                <TableCell>{benchmark.username}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </React.Fragment>
  );
}
