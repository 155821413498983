import React from 'react';
import DefaultLayout from '../../components/layouts/default-layout';
import StorageService from '../../services/local-storage/storage-service';
import { createBrowserHistory } from "history";
import SignIn from '../user/signin';
import SSOService from '../../services/api/sso-service';


export default function ForumSSO(props) {

  const payload = props.location.search;
  const user = StorageService.getUserInformation();
  const history = createBrowserHistory({ forceRefresh: true }); // TODO hacky solution

  const [loggedIn, setLoggedIn] = React.useState((user) ? true : false);

  React.useEffect(() => {
    if (loggedIn) {
      SSOService.sso(payload).then((response) => {
        setTimeout(() => history.push("/forum/"), 1000);
      }).catch((error) => {
        history.push('/');
        
        return error;
      })
    }
  }, [loggedIn])

  return (
    (loggedIn)
    ? (
        <DefaultLayout>
          <p>Redirecting to forum</p>
        </DefaultLayout>
      )
    : <SignIn sso={setLoggedIn}/>
  )
}