import React from "react";
import { DefaultPlayer as Video } from "react-html5video";
import "react-html5video/dist/styles.css";
import BlogCard from "../components/cards/blog-card";
import StatisticCard from "../components/cards/statistic-card";

import { Typography, Button, Grid } from "@material-ui/core";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";

import baseStyles from "../css/const.js";
import tools from "../json/tools.json";
import news from "../json/news.json";
import { Link } from "react-router-dom";
import Paper from "@material-ui/core/Paper";
import DefaultLayout from "../components/layouts/default-layout";
import useTracker from "../hooks/use-tracker";
// import UserService from "../services/api/user-service";
import useDocTitle from "../hooks/use-doc-title";
import UserService from "../services/api/user-service";
import SolutionService from "../services/api/solution-service";
import ScenarioService from "../services/api/scenario-service";

export default function Index() {
  const [title, setTitle] = useDocTitle("CommonOcean");

  useTracker();

  const classes = baseStyles();

  const [showNews, setShowNews] = React.useState(false);
  const [showTools, setShowTools] = React.useState(false);
  const [userCount, setUserCount] = React.useState(1029);
  const [scenarioCount, setScenarioCount] = React.useState(1298);
  const [submissionCount, setsubmissionCount] = React.useState(239632);

  const renderNewButtonNews = () => {
    if (news.length > 3) {
      return (
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => setShowNews(!showNews)}
            className={classes.button}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
          >
            View {showNews ? "Less" : "More"}
          </Button>
        </Grid>
      );
    } else {
      return <div></div>;
    }
  };

  const renderNewButtonTools = () => {
    if (Object.keys(tools).length > 3) {
      return (
        <Grid item xs={12}>
          <Button
            variant="contained"
            onClick={() => setShowTools(!showTools)}
            className={classes.button}
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              display: "block",
            }}
          >
            View {showTools ? "Less" : "More"}
          </Button>
        </Grid>
      );
    } else {
      return <div></div>;
    }
  };

  React.useEffect(() => {
    UserService.totalUserCount().then((response) => {
      setUserCount(response.data.count);
    });
    ScenarioService.getScenarioCount().then((response) => {
      setScenarioCount(response.data);
    });
    SolutionService.totalSolutionCount().then((response) => {
      setsubmissionCount(response.data.count);
    });
  }, []);

  return (
    <DefaultLayout>
      <Grid container direction="column" alignItems="stretch" justify="center" spacing={3}>
        {/** Welcome */}
        <Grid item>
          <Paper className={classes.paper} square={true}>
            <Grid item container direction="row" justify="center" spacing={3} alignItems="center">
              <Grid item xs={12} md={6} style={{ marginTop: "-15px" }}>
                <img
                  style={{ width: "100%", maxWidth: "100%" , marginTop: "2vh"}}
                  alt="scenario"
                  src={require("../assets/co_flowchart.png").default}
                />
                {/*<Video
                  controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen", "Res"]}
                  poster={require("../assets/video/CR_intro_video_still.png").default}
                >
                  <source src={require("../assets/video/CR_intro_video.mp4").default} type="video/mp4" />
                </Video>*/}
              </Grid>

              <Grid item xs={12} md={6} style={{ maxWidth: "700px", paddingLeft: "25px", paddingRight: "20px" }}>
                <Typography className={classes.subtitle}>Introduction</Typography>
                <Typography align="left" style={{ marginTop: "8px" }}>

                  CommonOcean is a collection of composable benchmarks for motion planning of autonomous vessels and provides
                  researchers with means of evaluating and comparing their motion planners. A benchmark consists of a scenario
                  with a planning problem, a vessel model including vessel parameters, and a cost function composing a unique id.
                  Along with benchmarks, we provide tools for motion planning. For further information, see <Link to={"/getting-started"}>getting started</Link>.
                  
                </Typography>
              </Grid>
            </Grid>
          </Paper>
        </Grid>

        {/** Scenarios */}
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Scenarios</Typography>
        </Grid>

        <Grid item>
          <Paper className={classes.paper} square={true}>
            <Grid container direction="row" justify="center" spacing={2}>
              <Grid item xs={12} sm={12} md={4} style={{ textAlign: "left" }}>
                <Typography>
                Our scenario database is a continuously growing collection of naturalistic datasets, handcrafted,
                and automatically generated scenarios provided in our XML format. We also provide a dataset converter for AIS data.
                </Typography>
                {/*<Grid item xs={12} sm={12} md={12}>
                <StatisticCard text="Scenarios" count={scenarioCount} to="https://gitlab.lrz.de/tum-cps/commonocean-scenarios"></StatisticCard>
                </Grid>*/}
              </Grid>

              {/*<Grid item xs={12} sm={6} md={3}  style={{textAlign: "center"}}>*/}
              {/*  <img style={{width:"100%", maxWidth:"300px"}} src={require("../assets/27494_KS2_WX1_RUS_Bicycle-6_2_T-1_2018b.gif")} />*/}
              {/*</Grid>*/}
              <Hidden smDown>
              <Grid item xs={12} sm={8} md={3} style={{ textAlign: "center" }}>
                <img
                  style={{ width: "100%", height:"100%", borderStyle: "solid", borderWidth: "0.5px"}}
                  alt="scenario"
                  src={require("../assets/Scenario1_Gif.gif").default}
                />
              </Grid>
              </Hidden>
              
              <Grid item xs={12} sm={12} md={5} style={{ textAlign: "center" }}>
                <img
                  style={{ width: "100%", height: "100%", borderStyle: "solid", borderWidth: "0.5px"}}
                  alt="scenario"
                  src={require("../assets/Scenario4_Gif.gif").default}
                />
              </Grid>
              
            </Grid>

            
            {/*
            <Grid item container alignItems={"center"} spacing={2}>
              {/* Count of users 
              <Grid item xs={6} sm={4}>
                <StatisticCard text="Users" count={userCount}></StatisticCard>
              </Grid>
              
              <Grid item xs={6} sm={4}></Grid>
              <Grid item xs={6} sm={4}>
                <StatisticCard text="Scenarios" count={scenarioCount} to="https://gitlab.lrz.de/tum-cps/commonocean-scenarios"></StatisticCard>
              </Grid>
              <Grid item xs={6} sm={4}></Grid>
              {/* Count of submissions 
              <Grid item xs={12} sm={4}>
                <StatisticCard text="Benchmark submissions" count={submissionCount} to="/solutions/ranking"></StatisticCard>
              </Grid>
              

            </Grid> 
            */}

          </Paper>
        </Grid>

        {/** News
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>News</Typography>
        </Grid>

        <Grid item container spacing={2}>
          {news.slice(0, 3).map(function (item) {
            return (
              <Grid item xs={12} sm={6} md={4}>
                <BlogCard title={item.title} text={item.description} date={item.date} href={item.link}></BlogCard>
              </Grid>
            );
          })}
        </Grid>

        <Grid item>
          <Collapse in={showNews}>
            <Grid container spacing={2}>
              {news.slice(3).map(function (item) {
                return (
                  <Grid item xs={12} sm={6} md={4}>
                    <BlogCard title={item.title} text={item.description} date={item.date} href={item.link} />
                  </Grid>
                );
              })}
            </Grid>
          </Collapse>
        </Grid>

        {renderNewButtonNews()}*/}

        {/** Tools */}
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Tools</Typography>
        </Grid>

        <Grid item>
          <Grid container spacing={2}>
            {Object.keys(tools)
              .slice(0, 3)
              .map(function (item) {
                const general = tools[item].general;

                return (
                  <Grid item xs={12} sm={6} md={4}>
                    <BlogCard title={general.title} text={general.short_description} href={general.href} />
                  </Grid>
                );
              })}
          </Grid>
        </Grid>

        <Grid item>
          <Collapse in={showTools}>
            <Grid container spacing={2}>
              {Object.keys(tools)
                .slice(3)
                .map(function (item) {
                  const general = tools[item].general;

                  return (
                    <Grid item xs={12} sm={6} md={4}>
                      <BlogCard title={general.title} text={general.short_description} href={general.href} />
                    </Grid>
                  );
                })}
            </Grid>
          </Collapse>
        </Grid>

        {renderNewButtonTools()}

        <Grid item>
          <Paper className={classes.paper} square={true}>
            <Grid item container direction="row" justify="center" spacing={3} alignItems="center">

              <Grid item xs={12} md={8} style={{ maxWidth: "700px", paddingLeft: "25px", paddingRight: "20px"}}>
                <Typography className={classes.subtitle}>Introducing CommonOcean at ITSC 2022</Typography>
                <Typography align="left" style={{ marginTop: "8px" }}>

                This presentation provides an overview of the CommonOcean tools released in 2022.
                In detail, the different components of our composable benchmarks are introduced and
                we exemplify the usage with a simple motion planner. For more information, please have a look
                at our <a href={"https://mediatum.ub.tum.de/doc/1687691/1687691.pdf"}>paper</a>.
                  
                </Typography>
              </Grid>

              <Grid item xs={12} md={4} style={{ marginTop: "-7px" }}>
                <Video
                  controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen", "Res"]}
                  poster={require("../assets/video/CO_intro_still.png").default}
                >
                  <source src={require("../assets/video/CO_intro_video.mp4").default} type="video/mp4" />
                </Video>
              </Grid>

            </Grid>
          </Paper>
        </Grid>

        {/** Forum 
        <Grid item xs={12}>
          <Typography className={classes.subtitle}>Forum</Typography>
        </Grid>

        
        <Grid item xs={12}>
          <Typography variant="body1" align="center" style={{ fontWeight: "500" }}>
            Visit our forum to get help or give feedback.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <a target="_blank" rel="noopener noreferrer" href={process.env.REACT_APP_FORUM_URL}>
            <Button
              variant="contained"
              className={classes.button}
              style={{
                marginLeft: "auto",
                marginRight: "auto",
                display: "block",
              }}
            >
              Visit
            </Button>
          </a>
        </Grid>
        */}
      </Grid>
    </DefaultLayout>
  );
}
