import getInstance from "./instances";
import moment from "moment";
import axios from "axios";

export default class SlackAppService {
  static WEBHOOK = process.env.REACT_APP_SLACK_WEBHOOK;
  

  static async log(data) {
    const _log = {
      attachments: [
        {
          color: "#FF0000",
          blocks: [
            {
              type: "header",
              text: {
                type: "plain_text",
                text: "Error",
              },
            },
            {
              type: "section",
              fields: [
                {
                  type: "mrkdwn",
                  text: "*Source*\nFrontend",
                },
                {
                  type: "mrkdwn",
                  text: `*Status Code*: ${data.statusCode}`,
                },
                {
                  type: "mrkdwn",
                  text: `*Date*\n${data.date}`,
                }
              ],
            },
            {
              type: "section",
              text: {
                type: "mrkdwn",
                text: `*Stack*\n${data.stack}`,
              },
            },
            {
              type: "divider",
            },
          ],
        },
      ],
    };

    axios
      .post(this.WEBHOOK, JSON.stringify(_log), {
        withCredentials: false,
        transformRequest: [
          (data, headers) => {
            delete headers.post["Content-Type"];
            return data;
          },
        ],
      })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        Promise.reject(error.response);
      });
  }
}
