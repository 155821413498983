import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import React from "react";
import CheckboxForm from "../../../components/form-elements/checkbox";
import useDocTitle from "../../../hooks/use-doc-title";
import LargeLayout from "../../../components/layouts/large-layout";
import CustomCalendar from "../../../components/misc/custom-calendar";
import ActivityService from "../../../services/api/activity-service";
import StorageService from "../../../services/local-storage/storage-service";
import ScenariosContainer from "../../scenario/scenarios-container";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

import Papa from "papaparse";
import useTracker from "../../../hooks/use-tracker";
import ScenarioService from "../../../services/api/scenario-service";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";

function FileBrowser(props) {
  const inputRef = React.createRef();

  const onClick = () => {
    inputRef.current.click();
  };

  const onChange = (setFile) => (event) => {
    Papa.parse(event.target.files[0], {
      complete: function (results) {
        let temp = [];
        for (const arr of results.data) {
          let el = null;
          if (arr.length > 1) {
            // which means comma separated values
            // depending on the file name, set element types
            // at time of writing, only scenarios is in CSV type
            if (arr[0].trim().length === 0 || arr[1].trim().length === 0) {
              continue;
            }
            el = {
              scenario_id: arr[0].trim(),
              version: arr[1].trim(),
            };
          } else {
            if (arr[0].trim().length === 0) {
              continue;
            }
            el = arr[0];
          }
          temp.push(el);
        }
        setFile(temp);
      },
    });
  };

  return (
    <React.Fragment>
      <input type="file" ref={inputRef} style={{ display: "none" }} onChange={onChange(props.setFile)} />
      <Button variant="contained" color="primary" onClick={onClick}>
        {props.text}
      </Button>
    </React.Fragment>
  );
}

export default function ChallengeCreate(props) {
  const [pageTitle, setPageTitle] = useDocTitle("Create Challenge");
  useTracker();

  const user = StorageService.getUserInformation();
  const modelLabels = ["PM", "KS", "ST", "MB"];
  const typeLabels = ["1", "2", "3"];
  const costFunctionLabels = ["JB1", "SA1", "WX1", "SM1", "SM2", "SM3", "MW1", "TR1"];
  const labels = [
    {
      label: "It is a public challenge that is visible to everyone.",
      name: "public",
    },
    {
      label:
        "It's a private challenge and I am going to supply the emails of the users who are allowed to enroll. " +
        "Users need to create an account with this email and need to be logged in to see the challenge.",
      name: "private",
    },
  ];
  const permissionLabels = user.is_activity_creator ? labels : labels.filter((x) => x.name === "private");

  const editMode = "edit" in props;
  const activityId = props.id;
  const ParentComponent = editMode ? React.Fragment : LargeLayout;

  const [selected, setSelected] = React.useState([]);
  const [title, setTitle] = React.useState("");
  const [summary, setSummary] = React.useState("");
  const [description, setDescription] = React.useState("");
  const [startDate, setStartDate] = React.useState(new Date());
  const [endDate, setEndDate] = React.useState(new Date());
  const [model, setModel] = React.useState("");
  const [type, setType] = React.useState("");
  const [costFunction, setCostFunction] = React.useState("");
  const [permission, setPermission] = React.useState(user.is_activity_creator ? "" : "private");
  const [scenariosFile, setScenariosFile] = React.useState([]); // scenario-name, version
  const [adminsFile, setAdminsFile] = React.useState([]);
  const [usersFile, setUsersFile] = React.useState([]);
  const [hidden, setHidden] = React.useState(false);

  const [submitErrors, setSubmitErrors] = React.useState([]);
  const [submitting, setSubmitting] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  const [unknownScenarios, setUnknownScenarios] = React.useState([]);
  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    if (submitErrors.length > 0) {
      window.scrollTo(0, 0);
    }
  }, [submitErrors]);

  React.useEffect(() => {
    if (editMode) {
      ActivityService.getActivity(activityId).then((response) => {
        setTitle(response.data.title);
        setSummary(response.data.summary);
        setDescription(response.data.description);
        setStartDate(new Date(response.data.start_date));
        setEndDate(new Date(response.data.end_date));
        setModel(response.data.vehicle_models[0]);
        setType(response.data.vehicle_types[0]);
        setCostFunction(response.data.cost_functions[0]);
        setSelected(response.data.scenarios);
        setHidden(response.data.hidden);
        setPermission(response.data.public ? "public" : "private");
        setUsers(response.data.users);
      });
      setPageTitle("Edit Challenge");
    }
  }, [activityId]);

  // set selected scenarios in the table if scenarios file has changed
  React.useEffect(() => {
    ScenarioService.getAllScenarioIds()
      .then((response) => {
        const scenarioIds = response.data.scenario_ids;

        let unknownScenarios = [];
        let knownScenarios = [];
        for (const scenarioInFile of scenariosFile) {
          if (
            !scenarioIds.some(
              (scenarioId) =>
                scenarioId["scenario_id"] === scenarioInFile["scenario_id"] &&
                scenarioId["version"] === scenarioInFile["version"]
            )
          ) {
            unknownScenarios.push(scenarioInFile);
          } else {
            knownScenarios.push(scenarioInFile);
          }
        }
        setUnknownScenarios(unknownScenarios);
        setSelected(knownScenarios.concat(unknownScenarios));
      })
      .catch((error) => {
        return error;
      });
  }, [scenariosFile]);

  const handleCheckChangeModel = (event) => {
    if (event.target.checked) {
      setModel(event.target.name);
    } else {
      setModel("");
    }
  };

  const handleCheckChangeType = (event) => {
    if (event.target.checked) {
      setType(event.target.name);
    } else {
      setType("");
    }
  };

  const handleCheckChangePermission = (event) => {
    if (event.target.checked) {
      setPermission(event.target.name);
    } else {
      setPermission("");
    }
  };

  const handleCheckHidden = (event) => {
    setHidden(event.target.checked);
  };

  const handleCheckCostFunctionChange = (event) => {
    if (event.target.checked) {
      setCostFunction(event.target.name);
    } else {
      setCostFunction("");
    }
  };

  const handleSubmitClicked = () => {
    // set mode to submit
    setSubmitting(true);

    const _adminsFile = adminsFile.map((x) => {
      return { email: x, is_admin: true };
    });

    const _usersFile = usersFile.map((x) => {
      return { email: x, is_admin: false };
    });

    const data = {
      title: title,
      summary: summary,
      description: description,
      scenarios: selected,
      start_date: startDate.toISOString(),
      end_date: endDate.toISOString(),
      vehicle_models: [model],
      vehicle_types: [type],
      cost_functions: [costFunction],
      users: _adminsFile.concat(_usersFile).concat(users),
      hidden: hidden,
      public: permission === "public",
    };

    if (editMode) {
      ActivityService.update(activityId, data)
        .then((response) => {
          // update local storage information as well
          for (let i = 0; i < user.admin_activities.length; i++) {
            if (user.admin_activities[i].id === activityId) {
              user.admin_activities[i].title = title;
            }
          }
          StorageService.storeUserInformation(user);
          props.setEdit(false);
        })
        .catch((error) => {
          const errors = Object.entries(error.data).map((error) => {
            // reconstruct field name nicely so that we can use it while showing the regarding error message
            const field = error[0]
              .replace("_", " ")
              .split(" ")
              .map((e) => e.charAt(0).toUpperCase() + e.slice(1))
              .join(" ");

            // Oth index holds the field name
            // 1st index holds the specific error message for the field
            if (Array.isArray(error[1])) {
              return `${field}: ${error[1][0]}`;
            } else if (typeof error[1] === "object") {
              return `${field}: ${error[1][0][0]}`;
            } else {
              return `${field}: ${error[1]}`;
            }
          });
          setSubmitErrors(errors);
        })
        .finally(() => {
          setSubmitting(false);
        });
    } else {
      ActivityService.create(data)
        .then((response) => {
          user.activities.push(response.data.id);
          user.admin_activities.push({
            id: response.data.id,
            title: response.data.title,
          });
          StorageService.storeUserInformation(user);
          window.location = "/challenges/admin"; //TODO: change the window location to another URI
        })
        .catch((error) => {
          const errors = Object.entries(error.data).map((error) => {
            // reconstruct field name nicely so that we can use it while showing the regarding error message
            const field = error[0]
              .replace("_", " ")
              .split(" ")
              .map((e) => e.charAt(0).toUpperCase() + e.slice(1))
              .join(" ");

            // Oth index holds the field name
            // 1st index holds the specific error message for the field
            if (Array.isArray(error[1])) {
              return `${field}: ${error[1][0]}`;
            } else if (typeof error[1] === "object") {
              return `${field}: ${error[1][0][0]}`;
            } else {
              return `${field}: ${error[1]}`;
            }
          });
          setSubmitErrors(errors);
          console.log(error);
        })
        .finally(() => {
          setSubmitting(false);
        });
    }
  };

  return (
    <ParentComponent>
      <Grid container alignItems="flex-start" spacing={2}>
        {submitErrors.length !== 0 && (
          <Grid item container xs={12} component={Paper}>
            {submitErrors.map((error) => (
              <Grid item xs={12}>
                <Alert severity="error">{error}</Alert>
              </Grid>
            ))}
          </Grid>
        )}

        <Grid item xs={12}>
          <Typography variant="h3">Create a challenge</Typography>
        </Grid>
        <Grid item>
          <Typography variant="h6">Below you can name your challenge and give it a description.</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            key="title"
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            label="Title"
            helperText="Title of the challenge. Maximum 250 characters"
            variant="outlined"
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={summary}
            onChange={(event) => setSummary(event.target.value)}
            label="Summary"
            helperText="Summary of the challenge that will be displayed on the overview page. Maximum 500 characters"
            variant="outlined"
            fullWidth
            required
          />
        </Grid>

        <Grid item xs={12}>
          <TextField
            value={description}
            onChange={(event) => setDescription(event.target.value)}
            label="Description"
            helperText="Description of the challenge. Can be in markdown format"
            variant="outlined"
            multiline
            rows={10}
            fullWidth
            required
          />
        </Grid>
      </Grid>

      <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item>
          <Typography variant="h6">Select start and end dates:</Typography>
        </Grid>
        <Grid item container spacing={2} justify="space-evenly">
          <Grid item>
            <Typography variant="body1">Start Date (*required)</Typography>
            <CustomCalendar label="Start Date" value={startDate} onChange={setStartDate} />
          </Grid>

          <Grid item>
            <Typography variant="body1">End Date</Typography>
            <CustomCalendar label="End Date" value={endDate} onChange={setEndDate} />
          </Grid>
        </Grid>
      </Grid>

      {user.is_activity_creator && (
        <React.Fragment>
          <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />
          <Grid container alignItems="flex-start" justify="flex-start" spacing={2}>
            <Grid item>
              <Typography variant="h6">
                Should participants submit their planners as docker images, which are evaluated using unknown scenarios?
                Otherwise, they must submit XML solution files for known scenarios.
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControlLabel
                control={<Checkbox checked={hidden} onChange={handleCheckHidden} />}
                label="Evaluate using unknown scenarios."
              />
            </Grid>
          </Grid>
        </React.Fragment>
      )}

      <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

      <Grid container alignItems="flex-start" justify="flex-start" spacing={2}>
        <Grid item>
          <Typography variant="h6">Select which scenarios to include:</Typography>
        </Grid>

        <Grid item container direction="column" alignItems="flex-start" spacing={2}>
          <Grid item>
            <Typography variant="body2">
              Apply filters to narrow down the search and select the scenarios in the table below.
              <br />
              If you have already got a txt file of scenarios to add to the challenge, you can submit it via the below
              button. (For how to generate such a file, you must follow the rules in this{" "}
              {
                <a href={require("../../../assets/admin-templates/scenarios.txt").default} download="scenarios.txt">
                  template
                </a>
              }{" "}
              file.)
            </Typography>
          </Grid>

          <Grid item>
            <FileBrowser text="Scenarios File" setFile={setScenariosFile} />
          </Grid>

          {unknownScenarios.length > 0 && (
            <Grid item container spacing={1}>
              <Grid item>
                <Paper elevation={1}>
                  <Alert severity="warning">
                    Some warnings were thrown while parsing the scenarios file. Expand to see the details.
                    <IconButton
                      aria-label="expand row"
                      size="small"
                      style={{ marginLeft: "5px" }}
                      onClick={() => setCollapseOpen((open) => !open)}
                    >
                      {collapseOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                  </Alert>
                </Paper>
              </Grid>
              <Grid item>
                <Collapse in={collapseOpen} timeout="auto">
                  <Paper elevation={1} style={{ padding: "10px" }}>
                    {unknownScenarios.map((s) => {
                      return (
                        <Alert
                          style={{ margin: "5px" }}
                          severity="warning"
                        >{`${s.scenario_id} with version ${s.version} is not present in the database. Please revise your scenarios file again.`}</Alert>
                      );
                    })}
                  </Paper>
                </Collapse>
              </Grid>
            </Grid>
          )}
        </Grid>

        <Grid item xs={12}>
          <ScenariosContainer
            selectable={true}
            selected={selected}
            downloadable={false}
            public={!hidden}
            showPublicFilter={false}
            setSelected={setSelected}
          />
        </Grid>
      </Grid>

      <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

      <Grid container alignItems="flex-start" justify="flex-start" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Select vehicle model for the challenge (see <a href={"/model-cost-functions"}>documentation</a> for more
            information):
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <CheckboxForm row labels={modelLabels} selected={model} onChange={handleCheckChangeModel} />
        </Grid>
      </Grid>

      <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

      <Grid container alignItems="flex-start" justify="flex-start" spacing={2}>
        <Grid item>
          <Typography variant="h6">
            Select vehicle type for the challenge (see <a href={"/model-cost-functions"}>documentation</a> for more
            information):
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <CheckboxForm row labels={typeLabels} selected={type} onChange={handleCheckChangeType} />
        </Grid>
      </Grid>

      <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

      <Grid container alignItems="flex-start" justify="flex-start" spacing={2}>
        <Grid item>
          <Typography variant="h6">
            Select the cost function (see <a href={"/model-cost-functions"}>documentation</a> for more information):
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <CheckboxForm
            row
            labels={costFunctionLabels}
            selected={costFunction}
            onChange={handleCheckCostFunctionChange}
          />
        </Grid>
      </Grid>

      <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

      <Grid container alignItems="flex-start" justify="flex-start" spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h6">
            Submit the emails of the admin users, who should be able to edit this challenge
          </Typography>
        </Grid>

        <Grid item xs={12}>
          You can add several admins to your challenge by submitting a .txt file of mails of registered users. You must
          follow the rules in the{" "}
          {
            <a href={require("../../../assets/admin-templates/admins.txt").default} download="admins.txt">
              template
            </a>
          }{" "}
          file.
        </Grid>

        <Grid item xs={12}>
          <FileBrowser text="Admins File" setFile={setAdminsFile} />
        </Grid>
      </Grid>

      <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

      <Grid container alignItems="flex-start" justify="flex-start" spacing={2}>
        <Grid item>
          <Typography variant="h6">
            Select whom is permitted to enter the challenge
            {!user.is_activity_creator ? ". (Contact us if you want to host public challenges)" : null}
          </Typography>
        </Grid>

        {user.is_activity_creator ? (
          <Grid item xs={12}>
            <CheckboxForm labels={permissionLabels} selected={permission} onChange={handleCheckChangePermission} />
          </Grid>
        ) : (
          <Grid item xs={12}>
            You can add several participants to your challenge by submitting a .txt file of mails of registered users.
            You must follow the rules in the{" "}
            {
              <a href={require("../../../assets/admin-templates/admins.txt").default} download="users.txt">
                template
              </a>
            }{" "}
            file.
          </Grid>
        )}

        {permission === "private" && (
          <Grid item xs={12}>
            <FileBrowser text="Users File" setFile={setUsersFile} />
          </Grid>
        )}
      </Grid>

      <Divider style={{ marginTop: "20px", marginBottom: "20px" }} />

      <Grid container>
        <div style={{ position: "relative" }}>
          <Button variant="contained" color="primary" disabled={submitting} onClick={handleSubmitClicked}>
            Save
          </Button>
          {submitting && (
            <CircularProgress
              color="primary"
              size={24}
              style={{ position: "absolute", top: "50%", left: "50%", marginTop: -12, marginLeft: -12 }}
            />
          )}
        </div>
      </Grid>
    </ParentComponent>
  );
}
