import ReactMarkdown from "react-markdown";
import React from "react";
import baseStyles from "../../../css/const";
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { Divider, List, ListItem, ListItemIcon, ListItemText, Typography } from "@material-ui/core";

export default function ChallengeDescription(props) {
  const classes = baseStyles();

  return (
    <div>
      <ReactMarkdown className={classes.markdown_text} source={props.text} />
      <Divider style={{marginBottom: "20px"}} />
      <Typography>Challenge Admins</Typography>
      <List dense>
        {props.admins.map((admin) => {
          return (
            <ListItem key={admin.username}>
              <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
              <ListItemText primary={admin.username} />
            </ListItem>
          )
        })}
      </List>
      {props.children}
      
    </div>
  );
}
