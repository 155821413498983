import React from "react";
import { TextField, Button, Paper, Container } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Avatar from "@material-ui/core/Avatar";
import LockOutlinedIcon from "@material-ui/icons/LockOutlined";
import Typography from "@material-ui/core/Typography";
import DefaultLayout from "../../components/layouts/default-layout";
import { useHistory } from "react-router";
import useDocTitle from "../../hooks/use-doc-title";
import UserService from "../../services/api/user-service";
import useTracker from "../../hooks/use-tracker";

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: "100%", // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorMessage: {
    color: theme.palette.error.main,
  },

  textField: {
    "& input": {
      paddingBottom: "10px",
      paddingTop: "30px",
    },
  },
}));

export default function AccountRecovery(props) {
  // set document title
  const [title, setTitle] = useDocTitle("Account Recovery");
  useTracker();
  const classes = useStyles();
  const history = useHistory();

  const [success, setSuccess] = React.useState(false);

  const email = React.useRef(null);

  const handleRecovery = (evt) => {
    evt.preventDefault();

    UserService.recoverPassword(email.current.value)
      .then(() => {
        setSuccess(true);
        setTimeout(() => history.push("/"), 4000);
      })
      .catch(() => {
        setSuccess(false);
      });
  };

  return (
    <DefaultLayout>
      {!success ? (
        <Container component="main" maxWidth="xs">
          <div className={classes.paper}>
            <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
              Reset Password
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleRecovery}>
              <TextField
                className={classes.textField}
                inputRef={email}
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="email"
                label="Email Address"
                name="email"
                autoComplete="email"
                autoFocus
              />

              <Button type="submit" fullWidth variant="contained" color="primary" style={{ marginTop: "10px" }}>
                Send Password Reset Email
              </Button>
            </form>
          </div>
        </Container>
      ) : (
        <Paper className={classes.successPaper}>
          <Typography variant="h4">Success</Typography>
          <Typography variant="body2">
            We have sent an email with the instructions to reset your password to your email address.
          </Typography>
        </Paper>
      )}
    </DefaultLayout>
  );
}
