import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";

import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { Button, MenuItem, Menu, makeStyles, Typography, List, Collapse, ListItem } from "@material-ui/core";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

//TODO: move to main CSS file
const useStyles = makeStyles((theme) => ({
  menuButton: {
    fontWeight: "thick",
    fontSize: "1.0em",
    marginLeft: "25px",
    color: "white",
    outline: "0 !important",
  },

  menuItem: {
    fontSize: "0.9em",
    textDecoration: "none",
    color: 'black'
  },

  outerLink: {
    textDecoration: "none",
    color: "inherit",
    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
      color: "black",
    },
  },

  innerLink: {
    textDecoration: "none",
    color: "inherit",
    "&:focus, &:hover, &:visited, &:link, &:active": {
      textDecoration: "none",
      color: "black",
    },
  },
}));

function LinkMenuItem({ type, text, to, decoration, component, handleClose, ...rest }) {
  const Component = component;
  let menuProps;

  console.log('class', rest.className)
  if (type === "outer") {
    menuProps = {
      className: rest.className,
      component: "a",
      href: to,
      target: "_blank",
      rel: "noopener noreferrer",
      key: text,
      onClick: handleClose,
      ...rest,
    };
  } else {
    menuProps = {
      className: rest.className,
      component: Link,
      to: to,
      key: text,
      onClick: handleClose,
      ...rest,
    };
  }

  return (
    <MenuItem {...menuProps}>
      {text}
      {decoration && (
        <Typography display="inline" style={{ fontSize: "inherit", color: "grey", textTransform: "uppercase" }}>
          &nbsp;{decoration}
        </Typography>
      )}
    </MenuItem>
  );
}

function CollapsibleMenuItem(props) {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <MenuItem
        className={props.className}
        button
        onClick={() => setOpen((x) => !x)}
      >
        {props.text}
        {props.decoration && (
          <Typography display="inline" style={{ fontSize: "inherit", color: "grey", textTransform: "uppercase" }}>
            &nbsp;{props.decoration}
          </Typography>
        )}
        {open ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List>
          {props.menuItems.map((item) => {
            let menuItemProps = {
              to: item.to,
              text: item.text,
              onClick: props.handleClose,
              component: ListItem,
            };

            if (item.linkType === "outer") {
              menuItemProps = {
                ...menuItemProps,
                type: "outer",
                className: `${classes.menuItem} ${classes.outerLink}`,
              };
            } else {
              menuItemProps = {
                ...menuItemProps,
                type: "inner",
                className: `${classes.menuItem} ${classes.innerLink}`,   
            }
          }
            return <LinkMenuItem {...menuItemProps} style={{ paddingLeft: "34px" }} />;
          })}
        </List>
      </Collapse>
    </React.Fragment>
  );
}

//TODO: specifically check the type, if button or menu
export default function NavBarItem(props) {
  const classes = useStyles();

  // Hooks
  const [anchorEl, setAnchorEl] = React.useState(null);

  // Handlers
  const handleClick = (event) => {
    event.preventDefault();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let menuItems = null;
  if (props.item.type === "menu") {
    menuItems = props.item.menuItems.map((menuItem) => {
      const decoration = "decoration" in menuItem;

      if ("type" in menuItem && menuItem.type === "menu") {
        return (
          <CollapsibleMenuItem
            className={classes.menuItem}
            text={menuItem.text}
            decoration={decoration && props.decoration}
            menuItems={menuItem.menuItems}
            handleClose={handleClose}
          />
        );
      }
      return (
        <LinkMenuItem
          className={`${classes.menuItem} ${menuItem.linkType === "outer" ? classes.innerLink : classes.outerLink}`}
          type={menuItem.linkType}
          text={menuItem.text}
          to={menuItem.to}
          decoration={decoration && menuItem.decoration}
          component={MenuItem}
          handleClose={handleClose}
        />
      );
    });
  }

  return (
    <React.Fragment>
      {props.item.type === "button" ? (
        <Button
          aria-controls={props.item.text}
          className={classes.menuButton}
          style={{ textDecoration: "none", color: "white" }}
          component={Link}
          to={props.item.to}
        >
          {props.item.text}
        </Button>
      ) : (
        <div>
          <Button
            aria-controls={props.item.text}
            className={classes.menuButton}
            onClick={handleClick}
            endIcon={<ArrowDropDownIcon />}
          >
            {props.item.text}
          </Button>
          <Menu
            keepMounted
            id={props.id}
            anchorEl={anchorEl}
            onClose={handleClose}
            open={Boolean(anchorEl)}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
          >
            {menuItems}
          </Menu>
        </div>
      )}
    </React.Fragment>
  );
}

NavBarItem.propTypes = {
  item: PropTypes.object,
};
