import React from "react";
import CustomStatusBox from "../components/misc/custom-status-box";

export default function Develop() {
  return (
    <div>
      <CustomStatusBox status='warning' />
    </div>
  );
}
