import React, { useRef } from "react";

import {
  Typography,
  Card,
  CardContent,
  Collapse,
  Table,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  TableRow,
  Divider,
  Grid,
  TextField,
  Button,
} from "@material-ui/core";
import { fade, makeStyles, withStyles } from "@material-ui/core/styles";
import TreeView from "@material-ui/lab/TreeView";
import TreeItem from "@material-ui/lab/TreeItem";
import SvgIcon from "@material-ui/core/SvgIcon";
import PropTypes from "prop-types";

import { useSpring, animated } from "react-spring/web.cjs"; // web.cjs is required for IE 11 support
import DefaultLayout from "../../components/layouts/default-layout";
import LargeLayout from "../../components/layouts/large-layout";
import QuickAccess from "../../components/quick-access";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import Link from "@material-ui/core/Link";
import SolutionUpload from "../../components/solution/solution-upload";

// TODO: refactor with Grid component

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
  },

  main: {
    width: "100%",
    margin: "0 auto",
  },

  "row-1": {
    marginTop: "20px",
    marginBottom: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    
  },

  "docker-submissions": {
    marginTop: '20px',
    marginBottom: '20px',
  },

  item: {
    flexBasis: "45%",
  },

  "submission-format": {
    marginTop: "20px",
  },

  "xml-formats": {
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },

  "cost-and-vehicle-models": {
    marginTop: "20px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
});

function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 11.023h-11.826q-.375 0-.669.281t-.294.682v0q0 .401.294 .682t.669.281h11.826q.375 0 .669-.281t.294-.682v0q0-.401-.294-.682t-.669-.281z" />
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0zM17.873 12.977h-4.923v4.896q0 .401-.281.682t-.682.281v0q-.375 0-.669-.281t-.294-.682v-4.896h-4.923q-.401 0-.682-.294t-.281-.669v0q0-.401.281-.682t.682-.281h4.923v-4.896q0-.401.294-.682t.669-.281v0q.401 0 .682.281t.281.682v4.896h4.923q.401 0 .682.281t.281.682v0q0 .375-.281.669t-.682.294z" />
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon className="close" fontSize="inherit" style={{ width: 14, height: 14 }} {...props}>
      {/* tslint:disable-next-line: max-line-length */}
      <path d="M17.485 17.512q-.281.281-.682.281t-.696-.268l-4.12-4.147-4.12 4.147q-.294.268-.696.268t-.682-.281-.281-.682.294-.669l4.12-4.147-4.12-4.147q-.294-.268-.294-.669t.281-.682.682-.281.696 .268l4.12 4.147 4.12-4.147q.294-.268.696-.268t.682.281 .281.669-.294.682l-4.12 4.147 4.12 4.147q.294.268 .294.669t-.281.682zM22.047 22.074v0 0-20.147 0h-20.12v0 20.147 0h20.12zM22.047 24h-20.12q-.803 0-1.365-.562t-.562-1.365v-20.147q0-.776.562-1.351t1.365-.575h20.147q.776 0 1.351.575t.575 1.351v20.147q0 .803-.575 1.365t-1.378.562v0z" />
    </SvgIcon>
  );
}

function TransitionComponent(props) {
  const style = useSpring({
    from: { opacity: 0, transform: "translate3d(20px,0,0)" },
    to: { opacity: props.in ? 1 : 0, transform: `translate3d(${props.in ? 0 : 20}px,0,0)` },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  /**
   * Show the component; triggers the enter or exit states
   */
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles((theme) => ({
  iconContainer: {
    "& .close": {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${fade(theme.palette.text.primary, 0.4)}`,
  },
}))((props) => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

export default function NewSubmission() {
  // set document title
  const [docTitle, setDocTitle] = useDocTitle("New Submission");

  // setup tracker after setting the document title
  useTracker();

  const classes = useStyles();

  return (
    <LargeLayout>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={10} xl={10}>
          <div id="main" className={classes.main}>
            <div id="row-1" className={classes["row-1"]}>
              <div id="column-1" className={classes.item}>
                <Typography variant="h4">Submit Benchmark Results</Typography>
                <Typography variant="body1" style={{ marginBottom: "10px" }}>
                  Results to CommonOcean benchmarks have to be uploaded as .xml files according to format specified
                  below. After submitting your solution, you can check the result of the evaluation under{" "}
                  <Link href={"/user/submissions"}>my submissions</Link> before publishing them.
                </Typography>
                <SolutionUpload/>
              </div>

              <div id="column-2" className={classes.item}>
                <Typography variant="h4">Tutorial</Typography>
                <Typography variant="body1">
                  This <Link href={"/tutorials/commonocean-interface"}>tutorial</Link> for our tool CommonOcean
                  Input-Output guides through the process of opening a CommonOcean scenario, implementing a simple
                  trajectory planner, and finally creating and uploading a solution file.
                </Typography>
                {/* <img src={require("../../assets/Scenario4_Gif.gif").default} alt="tutorial preview" width="100%"/> */}
              </div>
            </div>

            <Divider />

            <div id="submission-format" className={classes["submission-format"]}>
              <Typography variant="h4">Submission Format</Typography>
              <Typography variant="body1">
                A comprehensive introduction to CommonOcean benchmarks can be found in <Link href={"https://mediatum.ub.tum.de/doc/1687691/1687691.pdf"}>our paper</Link>. In short, a benchmark
                consists of a cost function, a vessel model, and a motion planning scenario. The solution file has to
                be uploaded as a formatted XML file adhering to the{" "}
                <Link
                  href={
                    "https://gitlab.lrz.de/tum-cps/commonocean-io/-/blob/main/commonocean/common/XML_commonOcean_XSD.xsd"
                  }
                >
                  XML_commonOceanSolution_XSD
                </Link>
                .
              </Typography>

              <Typography variant="body1" style={{ marginTop: "10px" }}>
                A solution file contains the solutions for all planning problems in the scenario. Each planning problem
                solution can be either specified as a trajectory or as an input vector of the chosen vessel model. Both
                types have to specify ID of their planning problem in the "planningProblemId" attribute. The two
                possible solution formats are described below.
              </Typography>

              <div id="xml-formats" className={classes["xml-formats"]}>
                <div id="xml-format-trajectory" className={classes.item}>
                  <Typography variant="h5"> XML format for trajectories</Typography>
                  <Typography variant="body2">
                    Trajectories are submitted as state vectors describing the vessel state at each time step.
                    Depending on the vessel model in the planning problem, different Trajectories types containing
                    different state vectors need to be selected. We provide a shortened description of trajectory
                    formats, the full format can be found in the schema definition file.
                  </Typography>
                  <Card>
                    <CardContent>
                      <TreeView
                        defaultExpanded={["1", "2", "3", "4"]}
                        defaultCollapseIcon={<MinusSquare />}
                        defaultExpandIcon={<PlusSquare />}
                        defaultEndIcon={<CloseSquare />}
                      >
                        <StyledTreeItem nodeId="1" label="pmTrajectory">
                          <StyledTreeItem nodeId="1.1" label="pmState">
                            <StyledTreeItem nodeId="1.1.1" label="x"></StyledTreeItem>
                            <StyledTreeItem nodeId="1.1.2" label="y"></StyledTreeItem>
                            <StyledTreeItem nodeId="1.1.3" label="xVelocity: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="1.1.4" label="yVelocity: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="1.1.5" label="time: int"></StyledTreeItem>
                          </StyledTreeItem>
                        </StyledTreeItem>

                        <StyledTreeItem nodeId="2" label="vpTrajectory">
                          <StyledTreeItem nodeId="2.1" label="vpState">
                            <StyledTreeItem nodeId="2.1.1" label="x: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="2.1.2" label="y: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="2.1.3" label="xVelocity: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="2.1.4" label="yVelocity: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="2.1.5" label="time: int"></StyledTreeItem>
                          </StyledTreeItem>
                        </StyledTreeItem>

                        <StyledTreeItem nodeId="3" label="ypTrajecory">
                          <StyledTreeItem nodeId="3.1" label="ypState">
                            <StyledTreeItem nodeId="3.1.1" label="x: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="3.1.2" label="y: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="3.1.3" label="orientation: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="3.1.4" label="velocity: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="3.1.5" label="time: int"></StyledTreeItem>
                          </StyledTreeItem>
                        </StyledTreeItem>

                        <StyledTreeItem nodeId="4" label="tfTrajectory">
                          <StyledTreeItem nodeId="4.1" label="tfState">
                            <StyledTreeItem nodeId="4.1.1" label="x: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="4.1.2" label="y: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="4.1.3" label="orientation: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="4.1.4" label="xVelocity: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="4.1.5" label="yVelocity: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="4.1.6" label="yawRate: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="4.1.7" label="time: int"></StyledTreeItem>
                          </StyledTreeItem>
                        </StyledTreeItem>
                      </TreeView>
                    </CardContent>
                  </Card>
                </div>
                <div id="xml-format-input-vector" className={classes.item}>
                  <Typography variant="h5">XML format for input vectors</Typography>
                  <Typography variant="body2">
                    Vessel inputs are submitted as input vectors for the vessel model.
                  </Typography>

                  <Card>
                    <CardContent>
                      <TreeView
                        defaultExpanded={["1", "2", "3"]}
                        defaultCollapseIcon={<MinusSquare />}
                        defaultExpandIcon={<PlusSquare />}
                        defaultEndIcon={<CloseSquare />}
                      >

                        <StyledTreeItem nodeId="1" label="pmInputVector">
                          <StyledTreeItem nodeId="1.1" label="pmInput">
                            <StyledTreeItem nodeId="1.1.1" label="xAcceleration: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="1.1.2" label="yAcceleration: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="1.1.3" label="time: int"></StyledTreeItem>
                          </StyledTreeItem>
                        </StyledTreeItem>

                        <StyledTreeItem nodeId="2" label="vpInputVector">
                          <StyledTreeItem nodeId="2.1" label="vpInput">
                            <StyledTreeItem nodeId="2.1.1" label="xAcceleration: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="2.1.2" label="yAcceleration: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="2.1.3" label="time: int"></StyledTreeItem>
                          </StyledTreeItem>
                        </StyledTreeItem>

                        <StyledTreeItem nodeId="3" label="ypInputVector">
                          <StyledTreeItem nodeId="3.1" label="ypInput">
                            <StyledTreeItem nodeId="3.1.1" label="acceleration: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="3.1.2" label="yawRate: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="3.1.3" label="time: int"></StyledTreeItem>
                          </StyledTreeItem>
                        </StyledTreeItem>

                        <StyledTreeItem nodeId="4" label="ypInputVector">
                          <StyledTreeItem nodeId="4.1" label="ypInput">
                            <StyledTreeItem nodeId="4.1.1" label="forceOrientation: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="4.1.2" label="forceLateral: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="4.1.3" label="yawMoment: float"></StyledTreeItem>
                            <StyledTreeItem nodeId="4.1.4" label="time: int"></StyledTreeItem>
                          </StyledTreeItem>
                        </StyledTreeItem>



                      </TreeView>
                    </CardContent>
                  </Card>
                </div>
              </div>

              <div id="cost-functions-vehicle-models" className={classes["cost-and-vehicle-models"]}>
                <div id="cost-functions" className={classes.item}>
                  <Typography variant="h5">Cost Functions</Typography>
                  <Typography variant="body2">
                    Cost functions are specified by the IDs shown below. Each function consists of partial cost
                    functions and a weight. For details on all partial cost functions, have a look at the
                    "documentation".
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableCell>Cost Function ID</TableCell>
                        <TableCell>Partial Cost Functions and Weights</TableCell>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell>JB1</TableCell>
                          <TableCell>(T|1)</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>VRCO1</TableCell>
                          <TableCell>
                            (VRCO|1)
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>RC1</TableCell>
                          <TableCell>(R|1)</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>SB1</TableCell>
                          <TableCell>(T|0.1), (A|0.5), (R|200)</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>

                <div id="vehicle-models" className={classes.item}>
                  <Typography variant="h5">Vessel Models</Typography>
                  <Typography variant="body2">
                  Vessel models determine the dynamics for the ego vessel by a state-space model,
                  with different representations and dimensions. 
                  </Typography>

                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Model ID</TableCell>
                          <TableCell>Vessel Models</TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <TableRow>
                          <TableCell>PM</TableCell>
                          <TableCell>Point Mass</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>VP</TableCell>
                          <TableCell>Velocity-Constrained Point Mass</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>YP</TableCell>
                          <TableCell>Yaw-Constrained</TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell>3F/TF</TableCell>
                          <TableCell>Three Degrees of Freedom</TableCell>
                        </TableRow>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
              </div>
            </div>
          </div>
        </Grid>

        <Grid item xs={12} lg={2} xl={2} style={{ marginTop: "20px" }}>
          <QuickAccess />
        </Grid>
      </Grid>
    </LargeLayout>
  );
}
