const SCENARIO_ENUMS = {
  behaviorTypes: {
    INTERACTIVE: 'Interactive Simulation',
    SET_BASED: 'Set-Based Prediction',
    TRAJECTORY: 'Trajectory Prediction'
  },

  goalTypes: {
    VELOCITY: 'Velocity Interval',
    ORIENTATION: 'Orientation Interval',
    TIME_STEP: 'Time Interval',
    LANELET: 'Lanelet Goal Area',
    POSITION: 'Position Goal Area'
  },

  laneletTypes: {
    URBAN : 'Urban',
    COUNTRY : 'Country',
    HIGHWAY : 'Highway',
    DRIVE_WAY : 'Driveway',
    MAIN_CARRIAGE_WAY : 'Main Carriageway',
    ACCESS_RAMP : 'Access Ramp',
    EXIT_RAMP : 'Exit Ramp',
    SHOULDER : 'Shoulder',
    BUS_LANE : 'Bus Lane',
    BUS_STOP : 'Bus Stop',
    BICYCLE_LANE : 'Bicycle Lane',
    SIDEWALK : 'Sidewalk',
    CROSSWALK : 'Crosswalk',
    INTERSTATE : 'Interstate',
    INTERSECTION : 'Intersection',
    UNKNOWN : 'Unknown'
  },

  tags: {
    HARBOUR : 'Harbour',
    OPENSEA: 'Open Sea'
  },

  obstacleTypes: {
    UNKNOWN : 'Unknown',
    MOTORVESSEL : 'Motorvessel'
  },

  roadUser: {
    VEHICLE : 'Vehicle',
    CAR : 'Car',
    TRUCK : 'Truck',
    BUS : 'Bus',
    PRIORITY_VEHICLE : 'Priority Vehicle',
    MOTORCYCLE : 'Motorcycle',
    BICYCLE : 'Bicycle',
    PEDESTRIAN : 'Pedestrian',
    TRAIN : 'Train',
    TAXI : 'Taxi',
  },

  trafficSigns: {
    RIGHT_OF_WAY : 'Right of Way',
    PRIORITY : 'Priority',
    YIELD : 'Yield',
    STOP : 'Stop',
    MAX_SPEED : 'Maximum Speed',
    MIN_SPEED : 'Minimum Speed',
    UNKNOWN : 'Unknown',
  },

  trafficSignsDirections: {
    RIGHT : 'Right',
    STRAIGHT : 'Straight',
    LEFT : 'Left',
    LEFT_STRAIGHT : 'Left Straight',
    STRAIGHT_RIGHT : 'Straight Right',
    LEFT_RIGHT : 'Left Straight',
    ALL : 'All',

  }
}

export default SCENARIO_ENUMS;
