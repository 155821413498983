import React from 'react';
import ScenariosContainer from '../../scenario/scenarios-container';

export default function ChallengeScenarios(props){

  return (
  <React.Fragment>
    <ScenariosContainer activityId={props.id} downloadable={false}/>
  </React.Fragment>
  )
}