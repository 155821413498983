import React from "react";
import { Link } from "react-router-dom";
import { Typography, ListItemText, ListItem, Collapse, List } from "@material-ui/core";

import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";

import useStyles from "../../css/const";

/** Returns a custom component for the text content */
function CustomListItemText(props) {
  return (
    <React.Fragment>
      <ListItemText>
        {props.text}
        {props.decoration && (
          <Typography display="inline" style={{ fontSize: "inherit", color: "grey", textTransform: "uppercase" }}>
            &nbsp;{props.decoration}
          </Typography>
        )}
      </ListItemText>
    </React.Fragment>
  );
}

/** Returns a wrapper component over the text component to be used navigation list */
function CustomListItemContent(props) {
  const classes = useStyles();

  if (props.item.linkType === "inner") {
    return (
      <Link className={classes.innerLink} to={props.item.to}>
        <CustomListItemText text={props.item.text} decoration={props.item.decoration} />
      </Link>
    );
  } else {
    return (
      <a className={classes.outerLink} href={props.item.to} target="_blank" rel="noopener noreferrer">
        <CustomListItemText text={props.item.text} decoration={props.item.decoration} />
      </a>
    );
  }
}

function CollapsibleListItem(props) {
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <ListItem button onClick={() => setOpen(!open)} style={{ paddingLeft: "34px" }}>
        <CustomListItemContent item={props.link} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component='div' disablePadding style={{ paddingLeft: "34px" }}>
          {props.link.menuItems.map((menuItem) => {
            return (
              <ListItem key={menuItem.text} button onClick={props.closeDrawer} style={{ paddingLeft: "34px" }}>
                <CustomListItemContent item={menuItem} />
              </ListItem>
            );
          })}
        </List>
      </Collapse>
    </React.Fragment>
  );
}

/** Returns a collapsible component to use for smaller screen sizes */
export default function NavbarListItem(props) {
  const [open, setOpen] = React.useState(false);

  if (props.link.type === "button") {
    // single button
    return (
      <React.Fragment>
        <ListItem button onClick={props.closeDrawer}>
          <CustomListItemContent item={props.link} />
        </ListItem>
      </React.Fragment>
    );
  } else {
    // collapsible menu
    return (
      <React.Fragment>
        <ListItem button onClick={() => setOpen(!open)}>
          <CustomListItemContent item={props.link} />
          {open ? <ExpandLess /> : <ExpandMore />}
        </ListItem>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List disablePadding>
            {props.link.menuItems.map((menuItem) => {
              if ("type" in menuItem && menuItem.type === "menu") {
                return <CollapsibleListItem link={menuItem} closeDrawer={props.closeDrawer} />;
              }
              return (
                <ListItem key={menuItem.text} button onClick={props.closeDrawer} style={{ paddingLeft: "34px" }}>
                  <CustomListItemContent item={menuItem} />
                </ListItem>
              );
            })}
          </List>
        </Collapse>
      </React.Fragment>
    );
  }
}
