import { Grid, Typography } from "@material-ui/core";
import React from "react";
import SolutionService from "../../services/api/solution-service";
import DefaultLayout from "../../components/layouts/default-layout";
import BenchmarkTable from "../../components/benchmark/benchmark-table";


import baseStyles from '../../css/const';
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import { useHistory } from 'react-router-dom';

export default function ScenarioBenchmarks(props) {
  const classes = baseStyles();

  const history = useHistory();

  const scenarioId = props.match.params.scenarioId;
  const [benchmarks, setBenchmarks] = React.useState([]);

  const [title, setTitle] = useDocTitle(`Benchmarks of ${scenarioId}`);
  useTracker();

  // fetch benchmarks of the specific scenario
  // TODO: catch errors
  React.useEffect(() => {
    SolutionService.getBenchmarkNames(scenarioId)
      .then((response) => {
        return response.data.benchmarks;
      })
      .catch((error) => {
        history.replace(history.location.pathname, {
          errorCode: 'error'
        })
        console.log(error);
      })
      .then((benchmarkNames) => {
        const promises = benchmarkNames.map((benchmarkName) => {
          const params = {
            benchmarkId: benchmarkName,
            page: 1,
            page_size: 1,
          }
          return SolutionService.getBenchmarkRanking(params).then((response) => {
            return {
              benchmarkId: benchmarkName,
              cost: response.data.results[0].cost,
              username: response.data.results[0].user.username
            }
          });
        });

        return Promise.all(promises);
      })
      .then((responses) => {
        setBenchmarks(responses);
      });
  }, []);

  return (
    <DefaultLayout>
      <Grid container direction='column' alignItems='stretch' justify='center'>
        <Grid item>
          <Typography className={classes.subtitle}>
            Benchmarks of {scenarioId}
          </Typography>
        </Grid>

        <Grid item>
          <BenchmarkTable benchmarks={benchmarks} />
        </Grid>
      </Grid>
    </DefaultLayout>
  )
}
