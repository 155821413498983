import getInstance from "../instances";

export default class SSOService {
  static apiURL = `${process.env.REACT_APP_API_URL}sso/`;

  static async sso(params) {
    return getInstance("auth")
      .get(`${this.apiURL}${params}`)
      .then((response) => {
        return response; // server redirects
      })
      .catch((error) => {
        return error.response;
      });
  }
}
