import StorageService from "../local-storage/storage-service";
import TokenService from "./token-service";
import UserService from "./user-service";

export default class AuthenticationService {
  static async login(email, password) {
    return TokenService.getTokens(email, password)
      .then((tokens) => {
        StorageService.storeToken(tokens.accessToken, "ACCESS");
        StorageService.storeToken(tokens.refreshToken, "REFRESH");

        return StorageService.getUserId();
      }).then((userId) => {
        return UserService.get(userId)
      })
      .then((response) => {
        StorageService.storeUserInformation(response.data);
        return 'SUCCESS';
      })
      .catch((error) => {
        return Promise.reject(error);
      });
  }

  static logout() {
      StorageService.clearAll();
  }
}
