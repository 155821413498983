import React from "react";
import Button from "@material-ui/core/Button";

import Typography from "@material-ui/core/Typography";
import { Snackbar, CircularProgress, Grid, TextField } from "@material-ui/core";
import CustomSnackbarContent from "../misc/custom-snackbar-content";
import CustomDialog from "../misc/custom-dialog";

import baseStyles from "../../css/const";
import SolutionService from "../../services/api/solution-service";

function XMLSubmissionButton(props) {
  const classes = baseStyles();

  const [files, setFiles] = React.useState([]);
  const [uploaded, setUploaded] = React.useState(0);
  const [uploading, setUploading] = React.useState(false);
  const fileInputRef = React.createRef();

  const onInputChange = (event) => {
    setFiles(Array.from(event.target.files));
  };

  const onSelectClick = () => {
    fileInputRef.current.click();
  };

  const onSubmitClick = (event) => {
    event.preventDefault();
    if ("setDialogUploading" in props) {
      props.setDialogUploading(true);
    }

    setUploading(true);

    const forms = files.map((file) => {
      const formData = new FormData();
      formData.append("xml", file);

      return formData;
    });

    const promises = forms.map((form, idx) => {
      return SolutionService.uploadSolution(form)
        .then(() => {
          setUploaded((uploaded) => uploaded + 1);
          return {
            idx: idx,
            status: "success",
            benchmarkName: form.get("xml").name,
          };
        })
        .catch((error) => {
          setUploaded((uploaded) => uploaded + 1);
          const detail =
            error.data.solution_upload[0] === "'NoneType' object has no attribute 'replace'"
              ? "Not a valid solution file!"
              : error.data.solution_upload;
          return {
            idx: idx,
            status: "error",
            benchmarkName: form.get("xml").name,
            detail: detail,
          };
        });
    });

    Promise.all(promises)
      .then((responses) => {
        setUploading(false);
        setFiles([]);

        const total = responses.length;
        const errors = responses.filter((x) => x.status === "error").length;

        if (errors === total) props.setSnackbarStatus("error");
        else if (errors > 0) props.setSnackbarStatus("warning");
        else props.setSnackbarStatus("success");

        props.setResponses(responses);
        props.setSnackbarOpen(true);
      })
      .finally(() => {
        if ("setDialogUploading" in props) {
          props.setDialogUploading(false);
        }
        setUploaded(0);
      });
  };

  return (
    <React.Fragment>
      <input
        type="file"
        data-testid="file-input"
        multiple
        onChange={onInputChange}
        ref={fileInputRef}
        style={{ display: "none" }}
      />
      {files.length === 0 ? (
        <Button id="xml-button" className={classes.button} style={{ width: "100%" }} onClick={onSelectClick}>
          Select up to 1000 files
        </Button>
      ) : (
        <React.Fragment>
          <Button
            id="xml-button"
            className={classes.button}
            type="submit"
            style={{ width: "100%" }}
            onClick={onSubmitClick}
          >
            Upload
          </Button>
          <Typography style={{ textAlign: "center" }}>{`${files.length} file(s) selected`}</Typography>
        </React.Fragment>
      )}
      {uploading ? (
        <Grid container alignItems="center" justify="center" spacing={2}>
          <Grid item>
            <CircularProgress style={{ margin: 0 }} />
          </Grid>
          <Grid item>
            <Typography style={{ margin: 0 }}>
              Uploading file {uploaded} of {files.length}.
            </Typography>
          </Grid>
        </Grid>
      ) : null}
    </React.Fragment>
  );
}

function DockerSubmissionButton(props) {
  const classes = baseStyles();

  //states
  const [uploading, setUploading] = React.useState(false);
  const inputRef = React.useRef();

  const handleURLClick = (event) => {
    event.preventDefault();

    if ("setDialogUploading" in props) {
      props.setDialogUploading(true);
    }

    setUploading(true);

    const data = {
      activity_id: props.activityId,
      docker_url: inputRef.current.value,
    };

    SolutionService.uploadDockerSolution(data)
      .then(() => {
        props.setResponses([{ status: "success", benchmarkName: data.docker_url }]);
        props.setSnackbarStatus("success");
      })
      .catch((error) => {
        props.setResponses([{ status: "error", benchmarkName: data.docker_url, detail: error.data.detail }]);
        props.setSnackbarStatus("error");
      })
      .finally(() => {
        if ("setDialogUploading" in props) {
          props.setDialogUploading(false);
        }
        setUploading(false);
        props.setSnackbarOpen(true);
      });
  };

  return (
    <React.Fragment>
      <Grid item container xs={12} justify="center">
        <TextField
          placeholder="[your-username]/[your-repo] or registry.hub.docker.com/[your-username]/[your-repo]"
          inputRef={inputRef}
          variant="outlined"
          style={{ width: "100%" }}
          InputProps={{ style: { fontSize: 12 } }}
          inputProps={{ "data-testid": "docker-url-input" }}
        />
      </Grid>

      <Grid item container xs={12} justify="center">
        <Button className={classes.button} onClick={handleURLClick} style={{ marginTop: "10px" }}>
          Submit
        </Button>
      </Grid>
    </React.Fragment>
  );
}

export default function SolutionUpload(props) {
  // states
  const docker = props.docker;
  const [snackbarStatus, setSnackbarStatus] = React.useState("success");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [dialogOpen, setDialogOpen] = React.useState(false);
  const [responses, setResponses] = React.useState([]);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  let setDialogUploading = "setDialogUploading" in props ? props.setDialogUploading : undefined;

  return (
    <React.Fragment>
      {docker ? (
        <DockerSubmissionButton
          setResponses={setResponses}
          setSnackbarStatus={setSnackbarStatus}
          setSnackbarOpen={setSnackbarOpen}
          activityId={props.activityId}
          {...(setDialogUploading && { setDialogUploading: setDialogUploading })}
        />
      ) : (
        <XMLSubmissionButton
          setResponses={setResponses}
          setSnackbarStatus={setSnackbarStatus}
          setSnackbarOpen={setSnackbarOpen}
          {...(setDialogUploading && { setDialogUploading: setDialogUploading })}
        />
      )}

      <Snackbar
        open={snackbarOpen}
        onClose={handleSnackbarClose}
        autoHideDuration={snackbarStatus === "success" ? 6000 : null}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <CustomSnackbarContent
          text="Upload"
          status={snackbarStatus}
          handleDialogOpen={handleDialogOpen}
          handleSnackbarClose={handleSnackbarClose}
        />
      </Snackbar>

      <CustomDialog open={dialogOpen} handleClose={handleDialogClose} contents={responses} dialogOperation="Upload" />
    </React.Fragment>
  );
}
