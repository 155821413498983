import { Dialog, DialogContent, DialogTitle } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import React from 'react';


export default function CustomDialog(props) {
  return (
    <React.Fragment>
      <Dialog open={props.open} onClose={props.handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>{props.dialogOperation} Status</DialogTitle>
        <DialogContent>
          {props.contents.map((content, idx) => {
            return (
              <Alert key={idx} style={{ marginBottom: "10px" }} severity={content.status}>
                <AlertTitle>{content.benchmarkName}</AlertTitle>
                {content.detail ? content.detail : "Successful"}
              </Alert>
            );
          })}
        </DialogContent>
      </Dialog>
    </React.Fragment>
  );
}