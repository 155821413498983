import React, { useLayoutEffect, useState } from 'react';
import useParentWindow from '../hooks/use-parent-window';


export default function NotebookFrame(props) {
  
  const style = {
    margin: '0 auto',
    width: '100%',
    height: '100vh',
    overflow: 'hidden'
  };

  const iframeRef = React.createRef();
  useParentWindow(iframeRef);

  return (
    <React.Fragment>
      <iframe
        style={style}
        id="tutorial-notebook"
        ref={iframeRef}
        frameBorder='0'
        title={props.title}
        src={props.src}
      />
    </React.Fragment>
  )
}