import getInstance from "../instances";

export default class TokenService {
  static API_URL = `${process.env.REACT_APP_API_URL}token/`;

  static async getTokens(email, password) {
    return getInstance()
      .post(`${this.API_URL}`, {
        email: email,
        password: password,
      })
      .then((response) => {
        const accessToken = response.data.access;
        const refreshToken = response.data.refresh;

        return { accessToken, refreshToken };
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }

  static async verifyToken(token) {
    return getInstance()
      .post(`${this.API_URL}verify/`, { token })
      .then((response) => {
        return response.status;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });   
  }

  static async refreshToken(refreshToken) {
    return getInstance()
      .post(`${this.API_URL}refresh/`, { 'refresh': refreshToken })
      .then((response) => {
        return response.data.access;
      })
      .catch((error) => {
        return Promise.reject(error.response);
      });
  }
}
