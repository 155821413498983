import React, { useState } from "react";
import MomentUtils from "@date-io/moment";
import { DatePicker, TimePicker, DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

export default function CustomCalendar(props) {
  const [date, setDate] = useState(new Date());

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <DateTimePicker
        label={props.label}
        openTo="date"
        variant="static"
        inputVariant="outlined"
        value={props.value}
        onChange={props.onChange}
      />
    </MuiPickersUtilsProvider>
  );
}
