import React from "react";
import ReactMarkdown from "react-markdown";

import competitionOrganizers from "../../assets/markdowns/competition-organizers.md";
import {Paper, Typography} from "@material-ui/core";
import DefaultLayout from "../../components/layouts/default-layout";
import useDocTitle from "../../hooks/use-doc-title";
import useTracker from "../../hooks/use-tracker";
import Link from "@material-ui/core/Link";
import Grid from "@material-ui/core/Grid";
import QuickAccessCompetition from "../../components/quick-access-competition";
import baseStyle from "../../css/const";

export default function CompetitionOrganizers() {
  const [title, setTitle] = useDocTitle("Competition Organizers");
  const classes = baseStyle();
  useTracker();

  const [markdown, setMarkdown] = React.useState("");

  React.useEffect(() => {
    fetch(competitionOrganizers)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);

  return (
      <DefaultLayout>
        <Grid container direction={'row'} spacing={2}>
          <Grid item xs={12} md={10}>
            <Paper className={classes.paper}>
              <ReactMarkdown children={markdown}></ReactMarkdown>
            </Paper>
          </Grid>
          <Grid item xs={12} md={2}>
            <QuickAccessCompetition/>
          </Grid>
        </Grid>
      </DefaultLayout>
  );
}
