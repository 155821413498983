import React from "react";
import { Link } from "react-router-dom";

import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";

import Logo from "../logo";
import { Box, Button, Grid } from "@material-ui/core";

import NavBarItem from "./navbar-item";
import UserAccountMenu from "./user-account-menu";

import Drawer from "@material-ui/core/Drawer";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import List from "@material-ui/core/List";
import Hidden from "@material-ui/core/Hidden";
import links from "./navbar-links";
import StorageService from "../../services/local-storage/storage-service";
import NavbarListItem from "./navbar-list-item";

const drawerWidth = "25%";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  appBar: {
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },

  toolBar: {
    overflowX: "auto",
    overflowY: "auto",
    minHeight: "60px",
    background: "#17748A",
  },

  menuSelect: {
    paddingLeft: "20px",
  },

  menuButton: {
    marginLeft: "5px",
    color: "white",
    outline: "0 !important",
  },
  hide: {
    display: "none",
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  drawerHeader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "18px",
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
  },

  drawerButton: {
    marginLeft: "auto",
  },

  content: {
    flexGrow: 1,
    padding: theme.spacing(5),
  },

  typoContent: {
    marginBottom: 14,
  },

  divider: {
    marginTop: 10,
    marginBottom: 10,
  },

  menuItem: {
    fontSize: "0.9em",
  },

  a: {
    color: "inherit",
    textDecoration: "none !important",
  },

  list: {
    width: 250,
  },

  nested: {
    paddingLeft: "35px",
  },
}));

export default function NavBar() {
  const classes = useStyles();

  const [drawer, setDrawer] = React.useState(false);

  const openDrawer = () => {
    setDrawer(true);
  };

  const closeDrawer = () => {
    setDrawer(false);
  };

  return (
    <header>
      <AppBar position="static">
        <Toolbar className={classes.toolBar} variant="dense">
          {/** logo */}
          <Link to={"/"} style={{marginRight: '5vw'}}>
            <Logo />
          </Link>

          {/** navigation items */}
          <Hidden mdDown>
            <Grid container spacing={1} alignItems="flex-start">
              {links.map((link) => {
                return (
                  <Grid key={link.text} item>
                    <NavBarItem item={link} />
                  </Grid>
                );
              })}
            </Grid>
          </Hidden>
          {/*
          {StorageService.getUserInformation() !== null ? (
            <UserAccountMenu style={{ marginLeft: "auto" }} />
          ) : (
            <Button component={Link} to="/signin" style={{ marginLeft: "auto", color: "white" }}>
              Sign&nbsp;In
            </Button>
          )}
          */}
          <Hidden lgUp>
            <IconButton
              edge="end"
              display="none"
              className={classes.menuButton}
              onClick={openDrawer}
              color="inherit"
              aria-label="menu"
            >
              <MenuIcon />
            </IconButton>
          </Hidden>
        </Toolbar>
      </AppBar>

      {/* hide elements if the screen size is smaller than the breakpoint [lgUp] */}
      <Hidden lgUp>
        <Drawer anchor="top" open={drawer} onClose={closeDrawer}>
          <List component="nav">
            {links.map((link) => {
              return <NavbarListItem key={link.text} link={link} closeDrawer={closeDrawer} />;
            })}
          </List>
        </Drawer>
      </Hidden>
    </header>
  );
}
