import React from "react";
import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Menu, MenuItem, makeStyles, Button } from "@material-ui/core";

import { Link } from "react-router-dom";
import AuthenticationService from "../../services/api/authentication-service";
import StorageService from "../../services/local-storage/storage-service";

const useStyles = makeStyles((theme) => ({
  menuIcon: {
    outline: "0 !important",
    color: "white",
    textTransform: "none",
  },

  menuLink: {
    textDecoration: "none",
    color: "inherit",
  },

  menuItem: {
    fontSize: "0.9em",
  },
}));

export default function UserAccountMenu(props) {
  const classes = useStyles();

  const [anchor, setAnchor] = React.useState(null);

  const handleClick = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleClose = () => {
    setAnchor(null);
  };

  const handleLogout = () => {
    AuthenticationService.logout();
  };

  return (
    <div style={props.style}>
      <Button
        className={classes.menuIcon}
        aria-controls="account-menu"
        onClick={handleClick}
        startIcon={<AccountCircleIcon></AccountCircleIcon>}
      >
        {StorageService.getUserInformation().username}
      </Button>

      <Menu
        id="account-menu"
        anchorEl={anchor}
        open={Boolean(anchor)}
        onClose={handleClose}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      >
        <Link style={{ textDecoration: "none", color: "inherit" }} to="/profile">
          <MenuItem className={classes.menuItem} onClick={handleClose}>
            Profile
          </MenuItem>
        </Link>

        <Link style={{ textDecoration: "none", color: "inherit" }} to="/new-submission">
          <MenuItem className={classes.menuItem} onClick={handleClose}>
            New Submission
          </MenuItem>
        </Link>

        <Link style={{ textDecoration: "none", color: "inherit" }} to="/user/submissions">
          <MenuItem className={classes.menuItem} onClick={handleClose}>
            My Submissions
          </MenuItem>
        </Link>

        <Link style={{ textDecoration: "none", color: "inherit" }} to="/">
          <MenuItem className={classes.menuItem} onClick={handleLogout}>
            Logout
          </MenuItem>
        </Link>
      </Menu>
    </div>
  );
}
