const navbarLinks = [
  {
    text: "Getting Started",
    type: "button",
    linkType: "inner",
    to: "/getting-started",
  },

  {
    text: "Tools",
    type: "menu",
    menuItems: [
      {
        text: "CommonOcean Input-Output",
        linkType: "inner",
        to: "/commonocean-io",
      },
      {
        text: "CommonOcean Drivability Checker",
        linkType: "inner",
        to: "/commonocean-dc",
      },
      {
        text: "CommonOcean Rules",
        linkType: "inner",
        to: "/commonocean-rules",
      },
      {
        text: "Dataset Converter",
        linkType: "inner",
        to: "/dataset-converter",
      },
      {
        text: "Vessel Models",
        linkType: "inner",
        to: "/commonocean-models",
      },
      
      /*
      {
        text: "CommonOcean Search",
        linkType: "inner",
        to: "/commonocean-search",
      },
      {
        text: "CommonOcean Reinforcement Learning",
        linkType: "inner",
        to: "/commonocean-rl",
      },
      {
        text: "CommonOcean Simulation",
        linkType: "inner",
        to: "/commonocean-simulation",
      },*/
    ],
  },

  {
    text: "Benchmarks",
    type: "menu",
    menuItems: [
      {
        text: "Scenario Selection Tool",
        linkType: "inner",
        to: "/scenarios",
      },
      {
        text: "Scenario Repository",
        linkType: "outer",
        to: "https://gitlab.lrz.de/tum-cps/commonocean-scenarios",
      },
    ],
  },

  {
    text: "Community",
    type: "menu",
    menuItems: [
      {
        text: "Publications",
        linkType: "inner",
        to: "/publications",
      },
      {
        text: "Team",
        linkType: "inner",
        to: "/team",
      },
    ],
  },
];

export default navbarLinks;
